import { Component, OnInit, OnDestroy, ViewChild, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { UserCacheService } from 'src/app/svc/userCacheService';
import { UtilService } from 'src/app/svc/utilService';

import * as Enum from '../../api/Enum';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTDETAILS,
  templateUrl: './project-details.html',
  styleUrls: ['../../shared/css/header.scss', './project-details.scss']
})
export class ProjectDetails implements OnInit, OnDestroy {

  constructor(
    private currentProject: CurrentProjectService,
    private userCacheService: UserCacheService,
    private stateHelperService : StateHelperService,
    private utilService: UtilService
  ) { 
  }

  private subscriptions: Array<Subscription> =new Array<Subscription>();

  ngOnInit(): void {
    this.subscriptions.push(this.stateHelperService.onIdChange().subscribe((v)=> {
      if (v.type == Enum.IdType.Project) {
        var uinf=this.userCacheService.GetUserInfo();
        this.utilService.localStorageHelper.setLastProject(uinf, v.id, this.utilService.serverId);
      }
    }));
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get serverId(): string {
    return this.utilService.serverId;
  }

  public get showSideNav() : boolean {
    return this.utilService.navigation && this.utilService.navigation.enabledCount() > 1;
  }

  public get isLoaded() : boolean {  
      return this.currentProject.dataContext.isReady;
  }

  public get isInError() : boolean {
      return this.currentProject.dataContext.isInError;
  }

  public get projectDetails() : CRS.RSGetProject {
        return this.currentProject.dataItem;
  }

  public get contextViewer(): string {
    return this.utilService.contextViewer || Constants.CTXT_VIEWER_PLATFORM;
  }

  public isFullWidth:boolean;
}
