import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { delay, repeat } from 'rxjs/operators';
import { NavigationService } from '../svc/navigationService';
import { Constants } from '../api/Constants';
import { UserCacheService } from '../svc/userCacheService';

@Component({
    selector: Constants.ROUTE_ERROR_SERVER_LOST,
    templateUrl: './error-server-lost.html',
    styleUrls:['./error-view.scss']
  })
 
export class ErrorServerLost implements OnInit, OnDestroy {

    constructor(
        private navigation: NavigationService,
        private userCacheService: UserCacheService
    ){
    }
   
    public goHome() {
        this.navigation.goHome();
    }

    private subscriptions : Array<Subscription> = new Array<Subscription>();
    public checkInterval : number = 2000;
    public checkCountAtInterval=0;
    private lastCheck : Date =new Date();
    public good: boolean = false;

    ngOnInit(): void {
        const delayedThing = of('timer').pipe(delay(2000));
        this.subscriptions.push(delayedThing.pipe(repeat())
          .subscribe(()=>this.check()));

        this.subscriptions.push(this.userCacheService.onUserLoaded().subscribe(()=>this.stateChanged()));
    }

    ngOnDestroy():void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    private stateChanged() {
        this.good = !this.userCacheService.inError;
    }

    private check() {
        const date2 = new Date();
        const diffTime = Math.abs(date2.valueOf() - this.lastCheck.valueOf());
        if (diffTime > this.checkInterval)
        {
            this.userCacheService.ensureUser(true);
            this.lastCheck= new Date();
            this.checkCountAtInterval++;
            if (this.checkCountAtInterval > 10) {
                this.checkCountAtInterval=0;
                if (this.checkInterval < 10000 * 6 * 60)
                    this.checkInterval *= 2;
            }
        }
    }
}