export class AppNote {

    constructor(id:string, label:string, value:string, cls:string, readonly:boolean) {
        this.id = id;
        this.label=label;
        this.value = value;
        this.cls=cls;
        this.readonly=readonly;
    }

    public id:string;
    public value:string;
    public label:string;
    public cls:string;
    public readonly:boolean;
}
