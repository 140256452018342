import { Component } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { StateHelperService } from '../../svc/stateHelperService';
import { NavigationService } from 'src/app/svc/navigationService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'conversion-task-failed',
  templateUrl: './conversion-task-failed.html',
})
export class ConversionTaskFailed {

    constructor(
        private currentConversionJob: CurrentConversionJobService,
        private navigation: NavigationService,
        private stateHelperService: StateHelperService,
        private utilService: UtilService
    ) { }

    public get job(): CRS.RSGetActionDetails {
      return this.currentConversionJob.dataItem;
    }
    
    public goToApp($event) : boolean {
      this.navigation.goApplicationJobFULL(
        this.stateHelperService.details.projectId,
        this.job.task.applicationId,
        this.job.task.id
      );
      return false;   
  }

  public isApplicationBasedRoute() {
      return !this.utilService.isEmpty(this.stateHelperService.details.applicationId);
  }

}
