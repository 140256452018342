import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ConversionService } from '../../api/SVC';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { ApplicationRelationshipType } from '../../api/Enum';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'create-install-dependency-dialog',
    templateUrl: 'create-install-dependency-dialog.html',
    styleUrls: ['../../shared/css/tables.scss']
})
export class CreateInstallDependencyDialog implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private currentProject: CurrentProjectService,
        private currentApplication: CurrentApplicationService,
        private conversionService: ConversionService
    ) {
    }

    public application: CRS.RSGetApplication;
    public candidateApplications: Array<CRS.RSApplication>=null;
    public relationshipType:number;
    public selectedSiblingApplication:CRS.RSApplication=null;
    public selectedProjectApplication:CRS.RSApplication=null;
    public selectedStandardPrerequisite=null;
    public selectedWindowsFeature:string=null;
    public searchText:string;

    public dependencyList: Array<CRS.RSDependencyItem>=null;
    public windowsFeatures: Array<CRS.RSDependencyItem>=null;

    public myControl = new FormControl();
    public filteredOptions: Observable<CRS.RSApplication[]>;

    public hasSiblingApplications: boolean=false;

    ngOnInit() {
        this.currentApplication.getApplicationsAtPath().then((data)=> {
            this.candidateApplications = data;
            this.hasSiblingApplications = data.length>0;
            this.relationshipType = (this.hasSiblingApplications) ? ApplicationRelationshipType.SiblingApplication : ApplicationRelationshipType.StandardPrerequisite;
        });
        this.conversionService.getDependencyList().then((response)=> {
            this.dependencyList=response.data.items.filter(x=>!x.isWindowsFeature);
            this.windowsFeatures = response.data.items.filter(x=>x.isWindowsFeature);
        })

        this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this.projectAppSearch(value))
        );
    }

    isDataReady() : boolean {
        return this.candidateApplications != null && this.dependencyList!=null;
    }

    projectAppSearch(value) {
        if (!value)
            return [];
        if (value.hasOwnProperty("filename")) // value is a single application
            return [ value ];
        var ltext = value.toLowerCase();
        var items =  this.currentProject.dataItem.applications.filter(x=>x.id != this.currentApplication.dataContext.id && x.name.toLowerCase().indexOf(ltext)!= -1);
        return items;
    }

    displayFn(app:CRS.RSApplication): string {
        return app ? app.name : '';
    }

    setSelected(app:CRS.RSApplication) {
        this.selectedProjectApplication=app;
    }

    isValid(): boolean {
        if (this.relationshipType == ApplicationRelationshipType.SiblingApplication)
            return this.selectedSiblingApplication != null;
        if (this.relationshipType == ApplicationRelationshipType.StandardPrerequisite)
            return this.selectedStandardPrerequisite != null;
        if (this.relationshipType ==  ApplicationRelationshipType.WindowsFeature)
            return this.selectedWindowsFeature !=null;
        if (this.relationshipType == ApplicationRelationshipType.ProjectApplication)
            return this.selectedProjectApplication != null;

        return false;
    }

    closeDialog() {
        var ret = {
            relationshipType:this.relationshipType, 
            value:null,
            display:null
        };
        if (this.relationshipType== ApplicationRelationshipType.SiblingApplication) {
            ret.value = this.selectedSiblingApplication.id;
            ret.display = this.selectedSiblingApplication.name;
        }
        if (this.relationshipType == ApplicationRelationshipType.ProjectApplication)
        {
            ret.value = this.selectedProjectApplication.id;
            ret.display = this.selectedProjectApplication.name;
        }
        if (this.relationshipType == ApplicationRelationshipType.StandardPrerequisite)
            ret.value = this.selectedStandardPrerequisite;
        if (this.relationshipType == ApplicationRelationshipType.WindowsFeature)
            ret.value = this.selectedWindowsFeature;
        this.dialogRef.close(ret);
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}

