import { Component, OnInit } from '@angular/core';
import { Constants } from '../../api/Constants';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSPUBLISH,
  templateUrl: './project-settings-publishing.html',
  styleUrls: [ './project-settings.scss', '../../shared/css/nav-bar.scss']
})
export class ProjectSettingsPublishing implements OnInit {

  constructor(
    public currentProject: CurrentProjectService) { }

  ngOnInit(): void {
  }

  public get project() : CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public get readmeDocumentationScripts() : Array<any> {
    return this.currentProject.readmeDocumentationScripts;
  }

  public get readmeDocumentationScriptsReady() : boolean {
    return this.currentProject.readmeDocumentationScriptsReady;
  }

  public publishingCommandsChanged(args : INPUTEDITARGS) {
    this.project.publishingCommands.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.publishingCommands);
  }

  public publishingCommandsVirtChanged(args : INPUTEDITARGS) {
    this.project.publishingCommandsVirtualisation.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.publishingCommandsVirtualisation);
  }

  public publishingCommandsMsixChanged(args : INPUTEDITARGS) {
    this.project.publishingCommandsMsix.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.publishingCommandsMsix);
  }
  
  public publishingCommandsLiqdChanged(args : INPUTEDITARGS) {
    this.project.publishingCommandsLiqd.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.publishingCommandsLiqd);
  }

  public publishingCommandsExeChanged(args : INPUTEDITARGS) {
    this.project.publishingCommandsExe.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.publishingCommandsExe);
  }

  public intuneInfoChanged(args : INPUTEDITARGS) {
    var pri : CRS.RSProjRuleInfo;
    switch(args.context) {
      case "intuneNotes": pri = this.project.intuneNotes;break;
      case "intuneOwner": pri = this.project.intuneOwner;break;
      case "intuneInfoUrl": pri =this.project.intuneInformationUrl;break;
      case "intuneCredentials": pri = this.project.intuneCredentials;break;
      case "intuneTenantId": pri = this.project.intuneTenantId;break;
      case "intuneClientId": pri = this.project.intuneClientId;break;
      case "intuneNavigatorIdElement": pri = this.project.intuneNavigatorIdElement;break;
    }
    if(pri!=null)
    {
        pri.value = args.value;
        this.currentProject.updateRuleGeneric(pri);
    }
  }

  public readmeDocumentationScriptChanged() {
    if (this.project.readmeDocumentationScript.value == "[NULL]")
      this.project.readmeDocumentationScript.value=null;
    this.currentProject.updateRuleGeneric(this.project.readmeDocumentationScript);
  }
}
