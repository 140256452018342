<div fxLayout="column" fxFill *ngIf="projectIsReady">

    <mat-tab-group fxFlex >
        <mat-tab label="Repackage">
            <div class="sw-mat-tab">
                <div>                       
                    <mat-checkbox [(ngModel)]="project.disableAutomaticDetectedDependencyInstall" (change)="disableAutomaticDetectedDependencyInstallChanged()" [disabled]="!canEdit()">Disable Automatic Detected Dependency Installation</mat-checkbox>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="X-Check">
            <div class="sw-mat-tab">
                <div>                       
                    <mat-checkbox [(ngModel)]="project.disableXCheck.boolValue" (change)="disableXCheckChanged()" [disabled]="!canEdit()">Disable X-Check</mat-checkbox>
                </div>
                <div>                       
                    <mat-checkbox [(ngModel)]="project.xcheckRequiresSilentArgs.boolValue" (change)="xcheckRequiresSilentArgsChanged()" [disabled]="!canEdit()">X-Check Requires Silent Args</mat-checkbox>
                </div>
                <div>                       
                    <mat-checkbox [(ngModel)]="project.sendXCheckVmCreationEmail.boolValue" (change)="sendXCheckVmCreationEmailChanged()" [disabled]="!canEdit()">Send Email After Virtual Machine Creation Completes</mat-checkbox>
                </div>
                <div>
                    <input-edit datatype="STRING" label="Image Tools SAS Key" [value]="project.xcheckImageToolsKey" (on-change)="xcheckImageToolsKeyChanged($event)" disable="!canEdit()"></input-edit>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Testing">
            <div class="sw-mat-tab">
                <div>
                    <h3>Discovery</h3>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="project.snapshotDuringDiscovery.boolValue" (change)="snapshotDuringDiscoveryChanged()" [disabled]="!canEdit()">Take snapshot during discovery action execution</mat-checkbox>
                </div>
                <div class="sw-big-vert-pad-top">
                    <h3>Testing Install User</h3>
                </div>
                <div>
                    <mat-radio-group [(ngModel)]="project.testUserType.value" (change)="testUserTypeChanged()" [disabled]="!canEdit()">
                        <mat-radio-button value="DFLT">Admin User (Default)</mat-radio-button>
                        <br>
                        <mat-radio-button value="SYS">System User</mat-radio-button>
                        <br>
                        <mat-radio-button value="ALT">Standard User</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="sw-big-vert-pad-top">
                    <h3>Products Pre-installed on build (one per row)</h3>
                </div>
                <div style="max-width: 450px;">
                    <input-edit datatype="MSTRING" label="Products pre-installed" [rows]="10" [value]="project.buildPreinstalledProducts.displayValue"
                            (on-change)="buildPreinstalledProductsChanged($event)" [disable]="!canEdit()"></input-edit>
                </div>

            </div>
        </mat-tab>
        <mat-tab label="App-V">
            <div class="sw-mat-tab">
                <div>                       
                    <mat-checkbox [(ngModel)]="project.appvFullWriteMode.boolValue" (change)="appvFullWriteModeChanged()" [disabled]="!canEdit()">App-V Full Write Mode</mat-checkbox>
                </div>
                <div>                       
                    <mat-checkbox [(ngModel)]="project.disable8Dot3FilenameCreation.boolValue" (change)="disable8Dot3FilenameCreationChanged()" [disabled]="!canEdit()">Disable 8.3 Filename Creation</mat-checkbox>
                </div>
                <div class="sw-big-vert-pad-bottom">                       
                    <mat-checkbox [(ngModel)]="project.showReportingConfigImageInReadme.boolValue" (change)="showReportingConfigImageInReadmeChanged()" [disabled]="!canEdit()">Show Reporting Config Image In Readme</mat-checkbox>
                </div>
            </div>
        </mat-tab>  
        <mat-tab label="MSIX">
            <div fxLayout="row" fxLayoutGap="30px" class="sw-mat-tab">
                <div fxFlex> <!-- col 1 -->
                    <div *ngIf="!project.msixCertificateIssuerExtracted">
                        <div class="sw-width-bs">
                            <input-edit datatype="STRING" label="MSIX Publisher Display Name" [value]="project.msixPublisherDisplayName.value" (on-change)="msixPublisherDisplayNameChanged($event)" [disable]="!canEdit()"></input-edit>
                        </div>
                    </div>
                    <div *ngIf="project.msixCertificateIssuerExtracted && project.msixCertificateSignError">
                        <div>MSIX Publisher Name couldn't be extracted from certificate (<span class='sw-warning-color'>{{project.msixCertificateSignError}}</span>)</div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div fxFlex="60" *ngIf="!project.msixCertificateIssuerExtracted">
                            <input-edit datatype="PUBLISHERNAME" label="MSIX Publisher Name" [value]="project.msixPublisherName.value" (on-change)="msixPublisherNameChanged($event)" [disable]="!canEdit()"></input-edit>     
                        </div>  
                        <div fxFlex="40" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="!project.msixCertificateIssuerExtracted">
                            <div fxFlex="none">
                                <mat-icon class="sw-warning md-24">warning</mat-icon>
                            </div>
                            <div fxFlex>
                                This should match your certificate details. e.g. CN=MyCorporation
                            </div>
                        </div>
                    </div>
                    <div *ngIf="project.msixCertificateIssuerExtracted && !project.msixCertificateSignError">
                        <div>MSIX Publisher Name extracted from certificate ({{project.msixCertificateIssuer}})</div>
                    </div>
                    <div fxLayout="row" class="sw-pad-top sw-pad-bottom">
                        <div fxFlex ng2FileDrop [uploader]="certUploadService.uploader" (fileOver)="dragFileOver($event)"
                            class="full-width-bs sw-cert-upload-container" style="overflow:hidden">
                            <div *ngIf="project.msixCertificate">
                                {{project.msixCertificate}}
                            </div>
                            <div *ngIf="!project.msixCertificate">
                                Drop MSIX signing certificate (.pfx)
                            </div>
                        </div> 
                        <div fxFlex="none" *ngIf="project.msixCertificateSignInProgress">
                            <div class="sw-left-pad">
                                <mat-spinner mode="indeterminate" diameter="20"></mat-spinner>
                            </div>  
                        </div>
                    </div>  
                    <div>
                        <input-edit datatype="PASSWORD" label="Certificate Password" [value]="project.msixCertificatePassword" (on-change)="msixCertificatePasswordChanged($event)" [disable]="!canEdit()"></input-edit>
                    </div>
                </div>  
                <div fxFlex> <!-- col 2 -->
                    <div class="full-width-bs">
                        <input-edit datatype="STRING" label="Signing Timestamp Url" [value]="project.signingTimestampUrl.value" (on-change)="signingTimestampUrlChanged($event)" [disable]="!canEdit()"></input-edit>
                    </div>  
                    <div>
                        <mat-checkbox [(ngModel)]="project.useVendorForPublisherDisplayName.boolValue" (change)="setUseVendorForPublisherDisplayNameChange()" [disabled]="!canEdit()" aria-label="Use vendor for publisher display name">Use Vendor for Publisher Display name</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="project.applyDelayedFixes.boolValue" (change)="setApplyDelayedFixesChange()" [disabled]="!canEdit()" aria-label="Apply delayed fixes">Apply Delayed Fixes</mat-checkbox>
                    </div>
                    <div class="sw-big-vert-pad-top">
                        <mat-form-field appearance="outline" class="full-width-bs" style="max-width:350px">
                            <mat-label>Msix Tool Version</mat-label>
                            <mat-select [(ngModel)]="project.msixPackagingToolVersion" [disabled]="!canEdit()" (change)="setMsixPackagingToolVersionChange()" placeholder="Msix Packaging Tool Version" style="margin:0px;width:300px">
                                <mat-option [value]='"*"'>Latest</mat-option>
                                <mat-option *ngFor='let item of currentProject.msixBundles' [value]='item.id'>{{item.display}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="full-width-bs">
                        <input-edit datatype="MSTRING" rows="10" label="Manifest Modifications" [value]="currentProject.msixManifestModificationsSplit" (on-change)="msixManifestModificationsChanged($event)" [disable]="!canEdit()"></input-edit>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Other">
            <div class="sw-mat-tab">
                <div>
                    <mat-checkbox [(ngModel)]="project.updatePasswordsOnVms.boolValue" (change)="updatePasswordsOnVmsChanged()" [disabled]="!canEdit()">Update passwords on Virtual Machines</mat-checkbox>   
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="project.preferInstallCommandFile.boolValue" (change)="preferInstallCommandFileChanged()" [disabled]="!canEdit()">Prefer install command file when launching conversion/test jobs</mat-checkbox>
                </div>
                <div *ngIf="project.preferInstallCommandFile.boolValue" class="sw-pad-horiz sw-big-vert-pad-top">
                    <input-edit datatype="STRING" label="Command Files" [value]="project.installCommandFiles" (on-change)="installCommandFilesChanged($event)"></input-edit>
                </div>
                <!--NO LONGER SUPPORTED <div>
                    <mat-checkbox [(ngModel)]="project.videoRecordTestingJobs.value" (change)="videoRecordTestingJobsChanged()" [disabled]="!canEdit()">Video record testing jobs</mat-checkbox>   
                </div> -->
                <div class="sw-vert-pad-bottom sw-big-vert-pad-top full-width-bs">
                    <input-edit datatype="JSON" [rows]="20" label="AppID Customisation" [value]="project.appIdCustomisations" 
                        (on-change)="appIdCustomisationsChanged($event)" [disable]="!canEdit()"></input-edit>
                </div>
            </div>
        </mat-tab>

    </mat-tab-group>

</div>  

<input id='projectUploadElement' multiple type="file" ng2FileSelect [uploader]="certUploadService.uploader" style='display: none' />
