import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs';
import { Constants } from '../../api/Constants'
import { NavigationService } from 'src/app/svc/navigationService';
import { Router,ActivatedRoute, NavigationExtras } from '@angular/router';
import * as STHS from '../../svc/stateHelperService';


@Component({
    selector: "admin-side-nav",
    templateUrl: './admin-side-nav.html',
    styleUrls: ['../../shared/css/nav-bar.scss']
})
export class AdminSideNav implements OnInit {

    // Make constants available in template
    public CONSTANTS = Constants;
    
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private navigation: NavigationService,
        public stateHelperService: STHS.StateHelperService
    ) {
    }

    private subscriptions: Array<Subscription> =new Array<Subscription>();

    ngOnInit(): void {
        this.gotoSub();
        this.stateHelperService.onStateChange().subscribe(()=> {
            this.gotoSub();
        });
    }
  
    ngOnDestroy(): void {
        this.subscriptions.forEach((s)=>s.unsubscribe());
    }

    public navItems = [
        { id:"ACTIVITY",    text: "Activity",               route: Constants.ROUTE_ADMIN_ACTIVITY,        icon: "event"},
        { id:"CHKLIB",      text: "Check Library",          route: Constants.ROUTE_ADMINCHECKLIBRARY,     icon: "airplay" },
        { id:"PATCHGRP",    text: "Patch Groups",           route: Constants.ROUTE_ADMINPATCHGROUPS,      icon: "insert_invitation" },
        { id:"PATCHCAT",    text: "Patch Category Files",   route: Constants.ROUTE_ADMINPATCHCATS,        icon: "storage" },
        { id:"PATCHCVECAT", text: "Patch CVE Categories",   route: Constants.ROUTE_ADMINPATCHCVECATS,     icon: "widgets" }, 
        { id:"ASSGRPS",     text: "Assessment Groups",      route: Constants.ROUTE_ADMINASSESSMENTGROUPS, icon: "collections" },
        { id:"PROJECTS",    text: "Projects",               route: Constants.ROUTE_ADMINPROJECTS,         icon: "insert_invitation" },
        { id:"WINUPD",      text: "Windows Updates",        route: Constants.ROUTE_ADMINWINDOWSUPDATES,   icon: "update" },
        { id:"MSGROUTES",   text: "Message Routes",         route: Constants.ROUTE_ADMINMSGROUTES,        icon: "route"},
        { id:"TASKMGR",     text: "Task Manager",           route: Constants.ROUTE_ADMINTASKMGR,          icon: "track_changes"},
        { id:"USERS",       text: "Users",                  route: Constants.ROUTE_ADMINUSERS,            icon: "person"}
      ]
    
    public getClass(ni:any) {
        var rc = this.stateHelperService.details.statePath;
        return rc.filter(x=>x.id == ni.route).length>0  ? "sw-nav-option-active" : "sw-nav-option-inactive";
    }

    public isMaximised() :boolean {
        return this.stateHelperService.details.statePath.length < 4;
    }

    public isReady() : boolean {
        return true;
    }

    public disableClick(): boolean {
        return false;
    }

    public conditionFilter(itemList: any[]): any[] {
        let result: any[] = [];
        itemList.forEach((v)=>{
            if (!v.condition || v.condition())
                result.push(v);
        })
        return result;
    }

    public isMinimised() :boolean {
        let result: boolean = true

        // Do not minimize the side nav for the following routes
        const noMinimised = [
            Constants.ROUTE_ADMININSTALLATIONS, 
            Constants.ROUTE_ADMINCHECKLIBRARY, 
            Constants.ROUTE_ADMINPATCHGROUPS, 
            Constants.ROUTE_ADMINPATCHCATS, 
            Constants.ROUTE_ADMINPATCHCVECATS,
            Constants.ROUTE_ADMINPROJECTS, 
            Constants.ROUTE_ADMINWINDOWSUPDATES,
            Constants.ROUTE_ADMINASSESSMENTGROUPS
        ];

        noMinimised.forEach(tab => {
            if(this.navigation.url == "/" + Constants.ROUTE_ADMINDASHBOARD) result =  false
            else if(this.navigation.url == "/" + Constants.ROUTE_ADMINDASHBOARD + "/" + tab) result = false
        })
        return result;
    }

    private gotoSub() {
        if (this.stateHelperService.details.isStatePathCurrent(Constants.ROUTE_ADMINDASHBOARD)) {
            var subId = localStorage.getItem(Constants.LOCALSET_ADMIN_SUB);
            if (!subId)
                subId = "INST";
            var navs = this.navItems.filter(x=>x.id == subId);
            if (navs.length>0)
                this.invoke(navs[0]);
        }
    }

    public invoke(item:any)
    {
        localStorage.setItem(Constants.LOCALSET_ADMIN_SUB, item.id);
        this.navigation.navigateToRouteFULL([Constants.ROUTE_ADMINDASHBOARD,item.route]);
    }

}