export enum LoadState {
    Empty,
    InTransition,
    Ready,
    InError
}

export enum SpineStatus {
    NotStarted=0,
    InProgress=1,
    Failed=2,
    Cancelled=3,
    Succeeded=4
}

export enum UserRegisterAction {
    Error,
    SignIn,
    ShowSlides
}

export enum ExploitabilityLevelType {
    Detected,
    MoreLikely,
    LessLikely,
    Unlikely,
    NotAffected
}

export enum GeneratedOutputType {
    None,
    Repackage,
    Virtualise,
    AppCompatReport,
    AutoFix,
    FileAdded,
    DriveLink,
    FileDeleted,
    CustomRuleFix,
    SecRpt,
    ResponseTransform,
    MsiExtract,
    EvaluationReport=81
}

export enum DriveItemStatus {
    None,
    Loading,
    Loaded,
    Locked
}

export enum ProjectRuleType
{
    AssessmentGroupDisable = 0,
    CheckDisable = 1,
    Standards = 2,
    CheckGroupIncludes = 3,
    NoteTypes = 4,
    AppIdCustomisations = 5,
    AppVFullWriteMode = 6,
    ConversionVHDs = 7,
    AppVDescriptionTemplate = 8,
    ReportingConfigurationId = 9,
    Disable8Dot3FilenameCreation = 10,
    AdditionalConnectionGroup = 11,
    PackagerName=12,
    DefaultAppIcon=13,
    PublishingCommands=14,
    PublishingCommandsVirtualisation=15,
    DetectionRegistryKey=16,
    ShowReportingConfigImageInReadme=17,
    DisableXCheck=18,
    MsixPublisherName=19,
    MsixPublisherDisplayName=20,
    PreferInstallCommandFile=21,
    InstallCommandFiles=22,
    VHDUsernameMap=23,
    UpdatePasswordsOnVms=24,
    WorkflowStageList=25,
    SendXCheckVmCreationEmail=26,
    XCheckImageToolsKey=27,
    SnapshotDuringDiscovery=28,
    ProjectNotes=29,
    ReadmeImageId=30,
    SigningTimestampUrl=31,
    MsixPackagingToolVersion=32,
    UseVendorForPublisherDisplayName=33,
    VideoRecordTestingJobs=34,
    ApplyDelayedFixes=35,
    PublishingCommandsMsix=36,
    MsixManifestModifications=37,
    ReportingFileFormat=38,
    ReadmeEngineeredBy=39,
    ReadmeVerifiedBy=40,
    ReadmeStandardsVersion = 41,
    ReadmeTemplateVersion = 42,
    ReadmeStandardsVersionLabel = 43,
    ReadmeTemplateVersionLabel = 44,
    ReadmeIncludeIceForOriginalPackage = 45,
    ReadmeIceErrorsOnly=46,
    PackagingStandardsVersion=47,
    TestUserType = 48,
    SendWorkflowStateChangeEmails=49,
    WorkflowStateChangeEmailHtml=50,
    WorkflowStateChangeEmailRecipient = 51,
    WorkflowStateChangeEmailTitle = 52,
    PublishingCommandsExe=53,
    QaChecksheetRules = 54,
    UsePackageIdFilenamesDuringPublish = 55,
    ShowEffortInAppCompatReport=56,
    ReadmeDocumentationScript=57,
    DisableAutomaticDetectedDependencyInstall=58,
    VMPreparationScript=59,
    VMPreparationScriptErrors = 60,
    PublishingCommandsLiqd=62,
    XCheckVhdCredentials = 63,
    XCheckRequiresSilentArgs = 64,
    QuickDriveScanStopPointDepth=65,
    UserGroup=66,
    intuneCredentials=67,
    intuneOwner=68,
    intuneNotes=69,
    intuneInformationUrl=70,
    intuneNavigatorIdElement=71,
    intuneTenantId=72,
    intuneClientId=73,
    buildPreinstalledProducts=74
}

export enum ShortcutTestResult
{
    NotFound,
    InstallationFailure,
    NoShortcuts,
    LaunchConditions,
    GeneralFailure,
    InProgress,
    Ok
}

export enum ShortcutScreenshotComparisonResult
{
    Unknown,
    AllEqual,
    MajorityEqual,
    Minority
}

export enum ArchitectureType {
    Unknown,
    x64,
    x86
}

export enum MonthlyPatchSeverityType
{
    Unknown,
    None,
    Low,
    Moderate,
    Important,
    Critical
}

export enum MonthlyPatchImpactType
{
    Unknown,
    RemoteCodeExecution,
    ElevationOfPrivilege,
    SecurityFeatureBypass,
    InformationDisclosure,
    Spoofing,
    DenialOfService,
    DefenseInDepth
}

export enum CCGState
{
    Unprocessed,
    Partial,
    Completed,
    ContainsErrors
}

export enum GridColumnType {
	Default,
	AppName,
	Percentage,
	Date,
	Tick,
	Status,
	RAG,
    XCheck,
    EvalState,
    Priority,
    WorkflowStage,
    PubStatus
}

export enum GridFilterType {
    Disabled,
    Default,
    Values,
    YesNo,
    DateRange,
    Status
}

export enum SoftwareType {
    Unsupported = 0,
    Windows,
    Office,
    Ie,
    Edge,
    Java,
    Adobe,
    DotNet,
    Exchange,
    Silverlight,
    WindowsServer,
    VisualBasic
}

export enum OutptType {
    Zip,
    PDF,
    Html
}

export enum IdType {
    Project,
    Application,
    AssessmentGroup,
    Job,
    Action,
    Check,
    Table
}

export enum CrumbChangeState {
    Initial,
    Pre,
    Post,
    Data
}

export enum ApplicationRelationshipType {
    None,
    DependentUpon,                  // Always a dependency between two apps within a project
    LaunchConditionDependency,       // A dependency to an app without any attempt to match it to a corresponding app within the same project.
    SiblingApplication,
    StandardPrerequisite,
    WindowsFeature,
    ProjectApplication
}

export enum ProjectErrorType {
    None,
    ProjectNotFound,
    Unknown
}

export enum WorkflowStageStatus{
    Open,
    Hold,
    Waiting,
    Cancelled
}

export enum Priority {
    Low,
    Medium,
    High
}