import { Injector } from '@angular/core';
import { Component, OnInit } from "@angular/core";
import { Constants } from "../../api/Constants";
import { GridAssistant } from 'src/app/model/gridAssistant';
import { NavigationService } from 'src/app/svc/navigationService';

import * as CRS from "../../api/CRS";
import * as SVC from "../../api/SVC";

@Component({
    selector: Constants.ROUTE_ADMINASSESSMENTGROUPS,
    templateUrl: "./admin-assessment-groups.html",
    styleUrls: ["../../shared/css/admin.scss", "../../shared/css/view.scss"]
})
export class AdminAssessmentGroups implements OnInit {

    public assessmentGroups: CRS.RSAdminGetAssessmentGroups;
    public selectedAssessmentGroupId: string;

    constructor(
        private injector: Injector,
        private navigation: NavigationService,
        private xadminService: SVC.AdminService,
    ) {}

    public gridAssist: GridAssistant<CRS.RSAdminGetAssessmentGroups, CRS.RSAdminAssessmentGroup>;

    ngOnInit() {
        this.gridAssist = new GridAssistant<CRS.RSAdminGetAssessmentGroups, CRS.RSAdminAssessmentGroup>(Constants.ROUTE_ADMINASSESSMENTGROUPS, this.injector);
        this.gridAssist.setColumns(   [         
                { id: 'id',   name: 'Id',   field: 'id',   sortable: true, width: 100 },
                { id: 'name', name: 'Name', field: 'name', sortable: true, width: 400 }
            ]
        );
        this.gridAssist.setDetailColumns([ "Id" ]);
        this.gridAssist.allowRowSelection((r)=>this.openAssessmentGroup(r));
        this.gridAssist.setUpdate(()=>this.update());
    }
    
    private update() {
        this.xadminService.adminGetAssessmentGroups().then((response) => {
            this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSAdminAssessmentGroup );
        }, () => {
            console.error("Data couldn't be loaded");
        });
    }

    // Open assessment group details screen
    public openAssessmentGroup(group: CRS.RSAdminAssessmentGroup) {
        this.selectedAssessmentGroupId = group.id;
        this.navigation.goAdminAssessmentGroupFULL(group.id)
    }
}