import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { CreateInstallDependencyDialog } from '../../dialogs/create-install-dependency/create-install-dependency-dialog';

import * as CRS from '../../api/CRS';

@Component({
  //selector: Constants.ROUTE_PROJECTAPPLICATIONHISTORY,
  selector: 'project-application-prerequisites',
  templateUrl: './project-application-prerequisites.html',
})
export class ProjectApplicationPrerequisites {

  constructor(
      private currentApplication: CurrentApplicationService,
      private dialog: MatDialog, 
  ) { }

  public isDataReady() : boolean {
    return this.currentApplication.dataContext.isReady;
  }

  public get application(): CRS.RSGetApplication {
    return this.currentApplication.dataItem;
  }

  public add($event: Event) {
    if ($event)
      $event.stopPropagation();
    const dialogRef = this.dialog.open(CreateInstallDependencyDialog);
    dialogRef.afterClosed().subscribe( answer => {
      if (answer)
        this.currentApplication.registerInstallDependency(answer.relationshipType, answer.value)
    });
    return false;
  }

  public moveUp(dep: CRS.RSApplicationInstallDependency) {
    this.currentApplication.moveUpInstallDependency(dep);
  }

  public moveDown(dep: CRS.RSApplicationInstallDependency) {
    this.currentApplication.moveDownInstallDependency(dep);
  }

  public delete(dep:CRS.RSApplicationInstallDependency) {
    this.currentApplication.deleteInstallDependency(dep);
  } 

}
