import { Injectable } from '@angular/core';
import { PlatformService } from './platformService';

import * as CRS from '../api/CRS'

@Injectable({
    providedIn: 'root'
})
export class RagValueResolverService {

    constructor( 
        private platformService: PlatformService
    ) { 
        
    }

    public resolve(projectId: string, ragElement: CRS.RSRAGElement, neutralise: boolean, explicitValue: string) : CRS.RSRAG{

        if (neutralise) {
            return RagValueResolverService.getNeutralised();
        }
        else if (ragElement) {
            let ret= ragElement.assessmentStatesDictionary[this.platformService.getCurrent(projectId)];
            return ret;
        }
        else if (explicitValue || explicitValue == "0") {
            return RagValueResolverService.buildExplicit(explicitValue);
        }

        return null;
    }

    public static resolveExplicit(explicitValue:string): CRS.RSRAG {
        return this.buildExplicit(explicitValue);
    }

    public static getNeutralised() : CRS.RSRAG {
        let rag = new CRS.RSRAG();
        rag.colorClass = 'noAssess';
        return rag; 
    }

    private static buildExplicit(value:string) : CRS.RSRAG {
        let nexplicitValue:number = this.explicitValueToNumber(String(value)); // force value to be a string. Can be coming in as numeric
        let colourClass:string =this.getColumnClass(nexplicitValue);
        var rsrag = new CRS.RSRAG();
        let ret = rsrag.deserialize(
            { 
                assessmentState: nexplicitValue, 
                platformNumber: 1,
                blueCount: (nexplicitValue == 50) ? 1 : 0,
                blueAppCount: 0,
                amberCount: (nexplicitValue == 100) ? 1 : 0,
                amberAppCount: 0,
                redCount: (nexplicitValue == 200) ? 1 : 0,
                redAppCount: 0, 
                greenCount: 0, 
                greenAppCount: 0,
                fixState : 0,
                colorClass:colourClass
            }
        );
        return ret;
    }

    private static explicitValueToNumber(explicitValue:string) : number {
        let nexplicitValue:number = 0;
        if (explicitValue.indexOf("0x") ==0) {
            return parseInt(explicitValue.substring(2), 16);
        }
        else {
            return parseInt(explicitValue);
        }
    }

    private static getColumnClass(value:number) : string {
        switch(value)
        {
            case 50: 
                return 'blue';
            case 100:
                return 'amber'
            case 200: 
                return 'red';
            default:
                return 'green';
        }
    }

}