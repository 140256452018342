import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { UtilService } from '../../svc/utilService';

@Component({
  selector: 'project-application-importing',
  templateUrl: './project-application-importing.html',
  styleUrls:['./project-applications.scss', '../../shared/css/forms.scss', '../../shared/css/buttons.scss']
})
export class ProjectApplicationImporting implements OnInit, OnDestroy {

  constructor(
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService,
      public utilService: UtilService
  ) { }

    private subscriptions : Array<Subscription> = new Array<Subscription>();
    public percentageComplete:number=-1;
  
    ngOnInit(): void {
       this.percentageComplete = this.getImportPercentage();
       this.subscriptions.push(this.currentProject.dataContext.onRefreshed().subscribe(()=>{
            this.percentageComplete = this.getImportPercentage();
        }));
        this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe(()=>{
            this.percentageComplete=this.getImportPercentage();
        }));
    }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get application() {
    return this.currentApplication.dataContext.item();
  }

  public getImportPercentage() :number {
     if (this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady) {
        var currApplicationId = this.currentApplication.dataContext.id;
        var app = this.currentProject.dataItem.applications.filter(x=>x.id == currApplicationId);
        return (app.length==1) ? app[0].importPercentComplete : 0;
     }
     return 0; 
  }

  public isDataReady() : boolean {
    return this.currentApplication.dataContext.isReady;
  }

}
