import { Component, OnInit } from '@angular/core';
import { Constants } from '../../api/Constants';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSSCRIPTS,
  templateUrl: './project-settings-scripts.html',
  styleUrls: [ './project-settings.scss', '../../shared/css/nav-bar.scss']
})
export class ProjectSettingsScripts implements OnInit {

  constructor(
    public currentProject: CurrentProjectService) { }

  ngOnInit(): void {
  }

  public get project() : CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(id:string): boolean {
    if (!this.currentProject.canEdit())
      return false;
    switch(id) {
        case "vmprep":
            return  this.project.vmPreparationScriptErrors.value != "pending";
    }
    return true;
  }

  public get isPending(): boolean {
    return this.currentProject.dataContext.isReady && this.project.vmPreparationScriptErrors.value == "pending";
  }

  public get isOk(): boolean {
    return this.currentProject.dataContext.isReady && this.project.vmPreparationScriptErrors.value == "ok";
  }

  public get errors(): Array<string> {
    if (!this.currentProject.dataContext.isReady)
        return [];
    var se =this.project.vmPreparationScriptErrors.value;
    if (!se || !se.startsWith("["))
        return [];
    var obj = JSON.parse(se);
    return obj;
  }

  public get vmPreparationScript() {
    return this.project.vmPreparationScript.value;
  }

  public set vmPreparationScript(value:string) {
    this.project.vmPreparationScript.value= value;
  }

  public vmPreparationScriptChanged(args : INPUTEDITARGS) {
    this.project.vmPreparationScriptErrors.value="pending";
    this.project.vmPreparationScript.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.vmPreparationScript);
  }

}
