import { Component, OnInit } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { UtilService } from '../../svc/utilService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';

import * as CRS from '../../api/CRS';

@Component({
    selector: "workflow-status-communication",
    templateUrl: './workflow-status-communication.html'
})
export class WorkflowStatusCommunication implements OnInit {

    constructor(
        private currentProject: CurrentProjectService,
        private utilService: UtilService
    ) {
    } 

    ngOnInit() {

    }

    public isDataReady() { 
        return this.currentProject.dataContext.isReady;
    }

    public canEdit(): boolean {
        return this.currentProject.canEdit();
    }

    public get project() : CRS.RSGetProject {
        return this.currentProject.dataItem;
    }

    public workflowStateChangeEmailTitleChanged(args:INPUTEDITARGS) {
        this.currentProject.dataItem.workflowStateChangeEmailTitle.value = args.value;
        this.currentProject.updateRuleGeneric(this.currentProject.dataItem.workflowStateChangeEmailTitle);
    }

    public workflowStateChangeEmailHtmlChanged(args:INPUTEDITARGS) {
        this.currentProject.dataItem.workflowStateChangeEmailHtml.value = args.value;
        this.currentProject.updateRuleGeneric(this.currentProject.dataItem.workflowStateChangeEmailHtml);
    }

    public workflowStateChangeEmailRecipientChanged(args:INPUTEDITARGS) {
        this.currentProject.dataItem.workflowStateChangeEmailRecipient.value = args.value;
        this.currentProject.updateRuleGeneric(this.currentProject.dataItem.workflowStateChangeEmailRecipient);
    }

    public sendWorkflowStateChangeEmailChanged() {
        this.currentProject.updateRuleGeneric(this.project.sendWorkflowStateChangeEmail);
    }
}