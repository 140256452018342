import { Injector } from '@angular/core';
import { NavigationService } from '../svc/navigationService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';

export class AdminAssGrpsChkBackCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.navigation = this.injector.get(NavigationService);

        this.menuService.register(Constants.MENU_ADMIN_ASSGRPS_CHK_BACK, this.cmd, "P-" + Constants.ROUTE_ADMINASSESSMENTGROUPS );
    }

    private menuService: MenuService;
    private navigation: NavigationService;

    // Navigate back to parent
    public cmd: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.navigation.goAdminAssessmentGroupsFULL();
        }
    )
    
}