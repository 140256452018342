import { Injectable } from '@angular/core';
import { UtilService } from '../svc/utilService';
import { SpineError } from '../model/SpineError';
import { Constants } from '../api/Constants';
import { NavigationService } from './navigationService';

import * as SVC from '../api/SVC';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    static $inject = ['$rootScope', '$http', '$state', 'utilService'];

    constructor(
        private navigation: NavigationService,
        private errSvc: SVC.ErrorService,
        private utilService: UtilService) {
    }

    public currentError:SpineError;
    public errorCode:string;

    public show(title: string, text: string, description: string, info: any): SpineError {
        let error = this.currentError = new SpineError(title, text, description, info);
        if (title == Constants.ERRTYPE_HTTP && info == 0) {
            this.navigation.goServerLostFULL();
        }
        else {
            try {
                this.recordError(error)
            }
            catch{}
            this.utilService.localStorageHelper.lastError = error;
            this.navigation.goErrorPage();
        }
        return error;
    }

    private recordError(err: SpineError) {
        this.errSvc.errorReport(err.code, err.title, err.text, err.description);
    }

    public getCurrentError(): SpineError {
        return this.currentError || SpineError.createDefaultError();
    }

    public clearCurrentError() {
        this.currentError = null;
    }

}
