<div fxLayout="row" *ngIf="projectIsReady">

    <div fxFlex fxLayout="column">

        <div fxFlex class="sw-big-pad-top" *ngIf="reportingConfigurationTypesReady && projectIsReady">
            <mat-form-field appearance="outline" style="min-width: 300px;">
                <mat-label>Reporting Configuration</mat-label>
                <mat-select [(ngModel)]="project.reportingConfiguration.value" [compareWith]="compareObjects"
                    (selectionChange)="reportingConfigurationChanged()" [disabled]="!canEdit()" style="min-width:200px">
                    <mat-option [value]="r.value" *ngFor="let r of reportingConfigurationTypes">{{r.text}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex class="sw-big-pad-top">
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxFlex="none">Default Application Icon</div>
                <image-upload [image]='project.defaultAppIcon.value' [disable]="!canEdit()" (on-change)="defaultAppIconImageChanged($event)"></image-upload>
            </div>
        </div>

    </div>  

    <div fxFlex fxLayout="column">
    <!-- <div fxFlex class="sw-big-pad-top">
            <mat-form-field appearance="outline" style="min-width: 300px;">
                <mat-label>Reporting File Format</mat-label>
                <mat-select [(ngModel)]="project.reportingFileFormat" [compareWith]="compareObjects"
                    (selectionChange)="reportingFileFormatChanged()" [disabled]="!canEdit()" style="min-width:200px">
                    <mat-option [value]="r" *ngFor="let r of reportingFileFormats">{{r}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>-->

        <div fxFlex="none" class="sw-big-pad-top">
            <h2>App Compat</h2>
        </div>

        <div fxFlex="none" class="sw-pad-top">
            <mat-checkbox [(ngModel)]="project.showEffortInAppCompatReport" (change)="showEffortInAppCompatReportChanged()" [disabled]="!canEdit()">Show effort calculations in report</mat-checkbox>
        </div>

        <div fxFlex="none" class="sw-big-pad-top">
            <h2>Readme</h2>
        </div>

        <div fxFlex="none" class="sw-pad-top">
            <mat-checkbox [(ngModel)]="project.readmeIceOnOriginalPackage.boolValue" (change)="readmeIceOnOriginalPackageChanged()" [disabled]="!canEdit()">List ICE issues for all packages</mat-checkbox>
        </div>

        <div fxFlex="none" class="sw-pad-top">
            <mat-checkbox [(ngModel)]="project.readmeIceErrorsOnly.boolValue" (change)="readmeIceErrorsOnlyChanged()" [disabled]="!canEdit()">Only list ICE errors (not warnings)</mat-checkbox>
        </div>

    </div>

</div>