<div class="full-width-bs full-height-bs">
    <spinner *ngIf="!gridAssist.isDataReady"></spinner>
    <angular-slickgrid [gridId]="gridAssist.gridId" gridHeight="99%" gridWidth="98%" *ngIf="gridAssist.isDataReady && gridAssist.hasAnyItems"
        [columnDefinitions]="gridAssist.columnDefs" 
        [gridOptions]="gridAssist.gridOptions"
        [dataset]="gridAssist.items"
        (onAngularGridCreated)="gridAssist.gridReady($event)"
        (onGridStateChanged)="gridAssist.gridStateChanged($event)">
    </angular-slickgrid>  
    <div *ngIf="gridAssist.isDataReady && !gridAssist.hasAnyItems && !gridAssist.errorMessage">{{gridAssist.noDataMessage}}</div>

    <div *ngIf="gridAssist.errorMessage" class="full-width-bs full-height-bs">
        <error-panel message="{{gridAssist.errorMessage}}"></error-panel>
    </div>

</div>
