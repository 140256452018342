import { Injector } from '@angular/core';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { NavigationService } from '../svc/navigationService';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentProjectService } from '../svc/currentProjectService';
import { UtilService } from '../svc/utilService';
import { StateHelperService } from '../svc/stateHelperService';
import { ConversionService } from '../api/SVC';

export class EditPackageCommand {

    constructor(
        private injector: Injector
    ) {
        this.navigation = this.injector.get(NavigationService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.utilService = this.injector.get(UtilService);
        this.conversionService = this.injector.get(ConversionService);
    }

    private navigation:NavigationService;
    private conversionService: ConversionService;
    private currentApplication: CurrentApplicationService;
    private utilService: UtilService;

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
             return this.currentApplication.dataContext.isReady 
                    && this.currentApplication.dataItem.isMsi
        },
        ()=> {
            this.conversionService.convertApplication(
                this.currentApplication.dataItem.projectId, 
                this.currentApplication.dataItem.id,
                "EditPackage",
                '',
                [],
                [],
                [],
                [],
                false,
                0,
                false,
                false,
                false, 
                false,
                false,
                '',
                false,
                false
                ).then((response)=> {
                    this.redirect(response.data.jobId);
                });
    
        },
        ()=>{
            return false;
        }
    );

    private redirect(jobId:string) { 
        this.currentApplication.dataContext.refreshCurrent().then((data)=> {
            this.navigation.goApplicationJobFULL(
                this.currentApplication.dataItem.projectId,
                this.currentApplication.dataItem.id,
                jobId
            );
        });
    }

}
