<mat-toolbar fxFlex="none" fxLayout="row" fxLayoutAlign="center center" [style.background]="getHeaderBgColor()" *ngIf="isStylingReady()">

    <div fxFlex="none"><div class="sw-pad-small"></div></div>

    <div fxFlex="none">
      <img [src]="getHeaderImage()" [ngClass]="getHeaderImageClass()" (click)="navigation.goHome()">
    </div>

    <div fxFlex="auto" class="header-logo">
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex class="head-logo" (click)="navigation.goHome()">
                <span class="header-title">readiness</span>
                <span class="header-stop">.</span>
            </div>
        </div>
    </div> 

    <div fxFlex></div>

    <div fxFlex="none" fxLayout="column">
      <div fxFlex fxLayout="row" fxLayoutAlign="end center" *ngIf="authService.isLoggedIn" fxLayoutGap="5px" class="sw-pad-top-small">
          <div fxFlex="none" *ngIf="isAdminUser()">
              <sw-button-text [text]="'Administrator options'" [icon]="'vpn_key'" (click)="navigation.goAdmin()" [collapsed]="true"></sw-button-text>
          </div>
          <div fxFlex="none" class="sw-pad-left-small">
            <sw-button-text [text]="'Help'" [icon]="'help'" (click)="showHelp()" [collapsed]="true"></sw-button-text>
          </div>
          <div fxFlex="none">
            <sw-button-text [text]="'About'" [icon]="'info'" (click)="showAbout()" [collapsed]="true"></sw-button-text>
          </div>
          <div fxFlex="none" *ngIf="authService.isLoggedIn">
            <sw-button-text [text]="'Logout'" [icon]="'cancel'" (click)="authService.logout()" [collapsed]="true"></sw-button-text>
          </div>
      </div> 
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <div fxFlex>
            <div class='sw-header-userinfo-username'  [style.color]="getHeaderFgColor()">{{user?.userName}}</div> 
            <div class='sw-header-userinfo-email' *ngIf='user?.userName != user?.userEmail'  [style.color]="getHeaderFgColor()">{{user?.userEmail}}</div>
            <div class='sw-error' *ngIf="userCacheService.inError">User authentication error</div>
          </div>
      </div>
    </div>

</mat-toolbar>