import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { NavigationService } from '../svc/navigationService';

export class AdminAssGrpsBackCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.navigation = this.injector.get(NavigationService);

        this.menuService.register(Constants.MENU_ADMIN_ASSGRPS_BACK, this.cmd, Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL_SLUG );
    }

    private menuService: MenuService;
    private navigation: NavigationService;

    // Navigate back to parent
    public cmd: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.navigation.goAdminAssessmentGroupsFULL();
        }
    )
    
}