<div fxLayout="column" fxFill>

  <div fxFlex="none" mat-dialog-title>
    <h3>PROJECT LIST</h3>
  </div>  

  <div mat-dialog-content fxFlex="none">

    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
      <div fxFlex="none" *ngIf="hasServerOptions">
        <mat-form-field appearance="outline">
          <mat-label>Server</mat-label>
          <mat-select [(ngModel)]="serverId" (selectionChange)="serverChanged()">
            <mat-option class="sw-option" *ngFor="let item of servers"  [value]="item.id">{{item.display}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="none" *ngIf="hasServerOptions">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput [(ngModel)]="searchText" type="text" placeholder="Search">
        </mat-form-field>
      </div>
      <div fxFlex="none" class="full-height-bs">
        <button mat-raised-button (click)="search()" style="height: 60px"><mat-icon>search</mat-icon></button>
      </div>
    </div>  
  </div>  

  <div fxFlex class="sw-container">

        <spinner *ngIf="!isDataReady()"></spinner>

        <div *ngIf="isDataReady() && hasProjects()" fxLayout="column" class="sw-content">
            <div *ngFor="let prj of projectList.projects" fxFlex="none" fxLayout="row" [ngClass]="isCurrent(prj) ? 'sw-current': ''" (click)="selectProject(prj)">
        
              <div fxFlex="none">
                <button mat-button>
                  <mat-icon class="project-image-small" title="{{prj.name}}" [backgroundImage]="currentProject.getProjectImageUrl(prj)" ></mat-icon>  
                </button>  
              </div>
              <div fxFlex>
                  <div fxLayout="column">
                      <div fxFlex>
                        <span class="sw-bold">{{prj.name}}</span>
                        <span>&nbsp;({{getAppCnt(prj)}})</span>
                      </div>
                      <div fxFlex>
                        <span>{{getProjectInfo(prj)}}</span>
                      </div>
                  </div>
              </div>
        
            </div>
        </div>

        <div *ngIf="isDataReady() && !hasProjects()">
          <h4>No projects were found</h4>
        </div>

    </div>


  <div fxFlex="none" mat-dialog-actions fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
    <div fxFlex></div>
    <div fxFlex="none">
      <button mat-raised-button color="primary" (click)="close(null)">Close</button>
    </div>
    <div fxFlex="none">
      <button mat-raised-button (click)="close('+')">Create</button>
    </div>
  </div>

  </div>  