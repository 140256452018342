import { Injector, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take} from 'rxjs/operators';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { UserCacheService } from '../svc/userCacheService';
import { UtilService } from '../svc/utilService';
import { ProjectListMenubarDialog } from '../project/project-list-menubar-dialog/project-list-menubar-dialog';
import { NavigationService } from '../svc/navigationService';

import * as CRS from '../api/CRS';

export class OpenProjectSelectorCommand {

    constructor(
        private injector: Injector
    ) {
        this.dialog = this.injector.get(MatDialog);
        this.navigation = this.injector.get(NavigationService);
        this.userCacheService = this.injector.get(UserCacheService)
        this.utilService = this.injector.get(UtilService);

        this.command = AppCommand.create(
            ()=> {
                return this.userCacheService.GetUserInfo() != null;
            },
            ()=> {
                // nothing 
            }
        );

        this.command.executeViaEvent = ($event:Event)=>{
            this.openProjectMenu($event);
        }
    }

    private userCacheService: UserCacheService;
    private utilService: UtilService;
    private navigation: NavigationService;
    private dialog: MatDialog;
    private router; Router;

    public user : CRS.RSGetUserInformation=null;

    public command: AppCommand ;

    public openProjectMenu($event: Event) {
        const target = new ElementRef(event.currentTarget);
        const dialogRef = this.dialog.open(ProjectListMenubarDialog, {
        data: { element:target },  minHeight:'600px'
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe( ret => {
            if (ret) {
                this.utilService.serverId = ret.server;
                var prj = ret.project;
                if (prj=="+") {
                    this.navigation.goProjectCreate(ret.server);
                }
                else {
                    this.utilService.localStorageHelper.setLastProject(this.user, prj.id, this.utilService.serverId);
                    this.navigation.goProjectDetailsFULL(prj.id);
                }
            }
        });
    }
}