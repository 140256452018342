import { Injector } from '@angular/core';
import { first,take } from 'rxjs/operators';
import { CurrentConversionJobService } from "../svc/currentConversionJobService";
import { DialogService } from '../svc/dialogService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { NavigationService } from '../svc/navigationService';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { UtilService } from '../svc/utilService';
import { CurrentProjectService } from '../svc/currentProjectService';

export class UnpublishCommand {

    constructor(
        private injector: Injector
    ) {
        this.navigation = this.injector.get(NavigationService);
        this.menuService = this.injector.get(MenuService);
        this.dialogService = this.injector.get(DialogService);
        this.currentConversionJob = this.injector.get(CurrentConversionJobService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.utilService = this.injector.get(UtilService);

        this.menuService.register(Constants.MENU_UNPUBLISH, this.cmd, Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN )
    }

    private navigation: NavigationService;
    private dialogService: DialogService;
    private currentConversionJob: CurrentConversionJobService;
    private currentApplication: CurrentApplicationService;
    private currentProject: CurrentProjectService;
    private menuService: MenuService;
    private utilService: UtilService;

    private cmd: AppCommand = AppCommand.create(
        ()=> {
            if (!this.currentConversionJob.dataContext.isReady || !this.currentConversionJob.hasTask())
                return false;
            var task = this.currentConversionJob.dataItem.task;
            return task.status == 4 && task.jobType == 30;
        },
        ()=> {
            this.dialogService.showYesNoMessage("Unpublish Application", ["Are you sure that you want to unpublish this application?"])
            .pipe(take(1))
            .subscribe((ans)=> {
                if (ans) {
                    let jobId = this.currentConversionJob.dataContext.id;
                    var act = this.currentApplication.findActionById(jobId);
                    if (act) {
                        this.currentConversionJob.resetJob().then(()=>{
                            this.currentApplication.dataContext.refreshCurrent();
                            this.currentConversionJob.dataContext.reload();
                            this.currentConversionJob.dataContext.onLoaded().pipe(first()).subscribe(()=>{
                                this.navigation.goApplicationJobFULL(this.currentProject.dataContext.id, this.currentApplication.dataContext.id, act.baseId)
                                    .then(()=>{
                                        this.menuService.refreshAll();
                                    });        
                            });
                        });
                    }
                }
            });
        }
    );

}
