import { Injector } from '@angular/core';
import { PollingService } from '../../svc/pollingService';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/api/Constants';
import { GridAssistant } from 'src/app/model/gridAssistant';

import * as UTIL from '../../svc/utilService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: Constants.ROUTE_ADMIN_ACTIVITY,
    templateUrl: './admin-activity.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss']
  })

export class AdminActivity implements OnInit, OnDestroy {

    constructor(
        public route: ActivatedRoute, 
        private injector: Injector,
        private pollingService: PollingService,
        private xadminService: SVC.AdminService,
        public utilService: UTIL.UtilService,
    ) {
    }
    
    public gridAssist: GridAssistant<CRS.RSAdminGetActivity, CRS.RSAdminActivityItem>;

    ngOnInit() {
        this.gridAssist = new GridAssistant<CRS.RSAdminGetActivity,CRS.RSAdminActivityItem>(Constants.ROUTE_ADMIN_ACTIVITY, this.injector);
        this.gridAssist.setColumns(   [         
            { id: 'activityDate', name: 'Date', field: 'created', sortable: true, width: 130,
                formatter:  (r,c,v,cd,dc)=> {
                    var valstr = this.utilService.getDateAsStringWithTime(v) ;
                    if (dc.exceptionDetails)
                        return `<div style="margin:0;padding:0;background-color:red" title="${dc.exceptionDetails}">${valstr}</div>`;
                    else
                        return `<div style="background-color:limegreen">${valstr}</div>`;
              }
            },
            { id: 'name', name: 'Name', field: 'name', sortable: true, width: 200 },
            { id: 'user', name: 'User', field: 'username', sortable: true, width: 200 },
            { id: 'type', name: 'Type', field: 'type', sortable: true, width: 200 },
            { id: 'message', name: 'Message', field: 'message', sortable: true, width: 400 }
            ]
        );
        this.gridAssist.setUpdate(()=>this.start());
    }

    ngOnDestroy() {
        this.gridAssist.dispose();
        this.pollingService.stopSequence(Constants.POLLING_ID_ADMINACTIVITY);
    }
  
    private start() {
        this.pollingService.startPollingSequence(
            Constants.POLLING_ID_ADMINACTIVITY,
            Constants.POLLING_NOTIFICATIONS_INTVL,
            -1,
            (resolve, reject) => { this.update(resolve, reject); }
        );
    }

    private update(resolve, reject): void {
        this.xadminService.adminGetActivity(7).then((response) => {
            this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSAdminActivityItem );
            resolve();
        }, () => {
            console.error('Server activity data couldn\'t be loaded');
            reject();
        });
    }
 

}

