import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Constants } from '../api/Constants';
import { ProjectService, ApplicationService, ConversionService, StandardsService } from '../api/SVC';
import { EventService } from '../svc/eventService';
import { UtilService } from '../svc/utilService';
import { AppNote } from '../model/AppNote';
import { YrMon } from '../model/YrMon';
import { PlatformDefn } from '../model/PlatformDefn';
import { PlatformService } from '../svc/platformService';
import { TrackedOperatingSystem } from '../model/TrackedOperatingSystem';
import { OutputItemInfoProj } from '../model/OutputItemInfoProj';
import { UserCacheService } from '../svc/userCacheService';
import { StateHelperService } from './stateHelperService';
import { AuthService } from './authService';
import { ErrorService } from '../svc/errorService';
import { DataContextProvider } from './DataContextProvider';
import { CustomRuleSet } from '../model/customRuleSet';
import { ProjectNotes } from '../model/projectNotes';
import { NotifyItem } from '../model/NotifyItem';

import * as Enum from '../api/Enum';
import * as CRS from '../api/CRS';

@Injectable({
    providedIn: 'root'
})
export class CurrentProjectService {

    private _onPlatformModified: Subject<PlatformDefn>;
    public onPlatformModified() {
        return this._onPlatformModified.asObservable();
    }
    private _onXCheckVirtualMachinesLoaded : Subject<boolean>;
    public onXCheckVirtualMachinesLoaded() {
        return this._onXCheckVirtualMachinesLoaded.asObservable();
    }
    private _onCheckVirtualMachineCreated: Subject<boolean>;
    public onXCheckVirtualMachineCreated() {
        return this._onCheckVirtualMachineCreated.asObservable();
    }
    private _onXCheckMetricsLoaded: Subject<boolean>;
    public onXCheckMetricsLoaded() {
        return this._onXCheckMetricsLoaded.asObservable();
    }

    constructor( 
        private authService: AuthService,
        private projectService: ProjectService,
        private applicationService: ApplicationService,
        private userCacheService: UserCacheService,
        private eventService: EventService,
        private conversionService: ConversionService,
        private platformService: PlatformService,
        private stateHelperService: StateHelperService,
        private utilService: UtilService,
        private errorService: ErrorService,
        public standardsService: StandardsService
    ) { 
        this.dataContext = new DataContextProvider<CRS.ResponseWrapper<CRS.RSGetProject>>(
            "Project",
            this.utilService,
            this.stateHelperService, 
            ()=>{return this.stateHelperService.details.projectId},
            ()=>{this.mainLoadPre()}, 
            (id,refresh)=>{return this.mainLoad(id,refresh)}, 
            ()=>this.mainLoadOk(),
            (data)=>this.partialRefresh(data));

        this.dataContext.setTimerInterval(Constants.POLLING_PROJECTDETAILS_INTVL);

        this._onPlatformModified = new Subject<PlatformDefn>();
        this._onXCheckVirtualMachinesLoaded = new Subject<boolean>();
        this._onCheckVirtualMachineCreated = new Subject<boolean>();
        this._onXCheckMetricsLoaded = new Subject<boolean>();

        this.authService.onLoggedIn().subscribe((state)=>{ this.checkInitialise(); });

        this.eventService.onProjectDetailsUpdateRequest().subscribe((data)=> {
            if (this.dataContext.id) {
                this.dataContext.refreshCurrent();
                if(this._customRuleSets!=null && this._customRuleSets[Constants.RULE_GROUP_STANDARDS]) 
                    this._customRuleSets[Constants.RULE_GROUP_STANDARDS].load();
                if(this._customRuleSets!=null && this._customRuleSets[Constants.RULE_GROUP_QACHECKSHEET]) 
                    this._customRuleSets[Constants.RULE_GROUP_QACHECKSHEET].load();
            }
        });
    }

    public dataContext: DataContextProvider<CRS.ResponseWrapper<CRS.RSGetProject>>;

    public msixManifestModificationsSplit:string;
    public msixBundles: Array<CRS.RSMsixBundle>=null;
    public publishCommandsSplit:{[key:string]:string};
    public workflowStagesSplit:string=null;

    private _platformNumber:number;
    private _customRuleSets: {[key:number]:CustomRuleSet}={};
    private _projectNotes: ProjectNotes=null;
    private _xcheckMetrics: CRS.RSGetXCheckMetrics=null;

    public get notes() : ProjectNotes {
        return this._projectNotes;
    }

    public get vhdsReady() {
        return !this.utilService.isEmptyAny(this.vhds);
    }
    public vhds: Array<CRS.RSVhd> = null;

    public get reportingConfigurationTypesReady() {
        return !this.utilService.isEmptyAny(this.reportingConfigurationTypes);
    }
    public reportingConfigurationTypes: Array<any> = null;

    public get readmeDocumentationScriptsReady() {
        return !this.utilService.isEmptyAny(this.readmeDocumentationScripts);
    }
    public readmeDocumentationScripts: Array<any> = null;

    public get xCheckAvailableVMsReady() {
        return !this.utilService.isEmptyAny(this.xcheckAvailableVMs);
    }
    public xcheckAvailableVMs : Array<CRS.RSVirtualMachine> = null;

    public hasProject() : Observable<string> {
        var obs = new Subject<string>()       
        this.projectService.hasProject().then((response)=> {
            var id = (this.utilService.isEmptyGuid(response.data.newestProject)) ? null : response.data.newestProject;
            obs.next(id);
        }).catch((err)=> {
            this.errorService.show(Constants.ERRTYPE_HTTP, err.message, err.statusText, err.status);
        });
        return obs;
    }

    public get ErrorType() : Enum.ProjectErrorType {
        return (this.dataContext.isInError) ? this.getErrorType(this.dataContext.ErrorReason) :Enum.ProjectErrorType.None;
    }

    public get imageUrl():string {
        if (!this.dataContext.isReady)
            return null;
        return this.getProjectImageUrl(this.dataItem);
    }

    public set imageUrl(value:string) {
        throw "not implemented";
    }

    public get userGroup():string {
        if (!this.dataContext.isReady)
            return null;
        return this.dataItem.userGroup.value;
    }

    public get customerReportingImageUrl() : string {
        if (!this.dataContext.isReady)
            return null;
        return (this.utilService.isEmptyGuid(this.dataItem.customerReportingImageId )) ? null : this.utilService.getAPIUrl( "Project/GetImage?ImageId=" + this.dataItem.customerReportingImageId + "&serverId=" + this.userCacheService.GetUserInfo().contextId);
    }
    public set customerReportingImageUrl(value:string) {
        throw "not implemented";
    }

    public canEdit(): boolean {
        return this.dataContext.isReady 
                && (this.dataItem.isOwner || this.dataItem.isContributor || this.utilService.privileges?.admin)
                && this.utilService.privileges?.canEditSettings;
    }

    public get platformNumber() :number {
        return this._platformNumber;
    }

    public getProjectImageUrl(proj: CRS.RSProject | CRS.RSGetProject):string {
        var user = this.userCacheService.GetUserInfo();
        if(!user)
            return null;
        if (!proj.imageId || this.utilService.isEmptyGuid(proj.imageId))
            return "/img/project/NoProjectImage.png";
        return this.utilService.getAPIUrl( "Project/GetImage?ImageId=" + proj.imageId + "&serverId=" + user.contextId);
    }

    public getWorkflowStages():Array<string> {
        if (this.dataItem) {
            var prj :CRS.RSGetProject = this.dataItem;
            if (prj)
                return prj.workflowStageList.map(x=>x.name);
            return [];
        }
        return null;
    }

    public getWorkflowStatuses():Array<string> {
        var lst = [];
        for(var e in Enum.WorkflowStageStatus)
            if (!parseInt(e) && e != "0")
                lst.push(e);
        return lst;
    }
    
    public setApplicationWorkflowState(applicationId: string, stage: number, state:number, assigneeId:string, assigneeName:string) : Promise<boolean> {
        return new Promise<boolean>((resolve,reject)=>{
            var app = this.dataItem.applications.find(x=>x.id == applicationId);
            if (app) {
                if (app.workflowStage != stage || app.workflowStageState != state || app.assignedToId != assigneeId) {
                    this.applicationService.setWorkflowStageAndState(applicationId, stage, state, assigneeId).then(()=>{
                        app.workflowStage = stage;
                        app.workflowStageState = state;
                        app.assignedToId = assigneeId;
                        var apm =this.dataItem.applications.filter(x=>x.id ==applicationId);
                        if (apm.length>0) {
                            apm[0].assignedToId = assigneeId;
                            apm[0].assignedToName = assigneeName;
                        }
                        resolve(true);
                    },(r)=>reject(r));
                }
                else {
                    resolve(true);
                }
            }
            else {
                reject("application not found");
            }
        });
    }

    public searchPackageSourceFolders(searchText:string): Promise<CRS.ResponseWrapper<CRS.RSSearchProjectPackageSourceFolders>> {
        return this.projectService.searchProjecPackageSourceFolders(this.utilService.serverId, this.dataContext.id, searchText)
    }

    public getCustomRuleSet(ruleSetId:number) : CustomRuleSet {
        return this._customRuleSets[ruleSetId];
    }

    public get xcheckMetrics() : CRS.RSGetXCheckMetrics
    {
        return this._xcheckMetrics;
    }

    public delete() : Promise<boolean> {
        return new Promise<boolean>((resolve,reject)=>{
            this.projectService.deleteProject(this.dataContext.id).then((ret)=>{
                resolve(true);
            }, (err)=>{
                reject(false);
            });
        });
    }

    public ensureCustomRuleSets(ruleSetId:number) {
        if (this._customRuleSets[ruleSetId]==null)
            this._customRuleSets[ruleSetId] = new CustomRuleSet(this, ruleSetId );
    }

    public clearImportFailures() {
        this.projectService.clearImportFailures(this.dataContext.id).then((ret)=> {
            this.dataContext.refreshCurrent();
        });
    }

    public setDesktopPlatformBaseImage(imageId:string) : Promise<boolean> {
        var desktop = this.dataItem.platforms.filter(x=>x.isDesktopPlatform)[0];
        return new Promise<boolean>((resolve,reject)=> {
            this.projectService.updateProjectPlatformBaseImage(desktop.platformGuid,imageId).then(v=> {
                this.dataContext.refreshCurrent();
                resolve(true);
            },(err)=> {
                reject(false);
            });
        });
    }

    public updateConversionTypeVhd(jobType, subType, vhd) : Promise<boolean> {
        return new Promise<boolean>((resolve,reject) =>{
            this.projectService.updateConversionTypeVhd(this.dataContext.id, jobType, subType, vhd).then((ret)=>{
                this.dataContext.refreshCurrent();
                resolve(true);
            },(err)=>{
                reject(false);
            });
        });
    }

    public getCurrentPlatformInfo() : CRS.RSPlatform {
        let sp = this.dataItem.platforms.filter((e, i) => { return e.id == this._platformNumber });
        return (sp.length==0) ? null :sp[0];
    }

    public get createSnapshotDuringDiscovery(): boolean {
        return this.dataContext.isReady && this.dataItem.snapshotDuringDiscovery.boolValue;
    }

    public addApplication(productName:string, productVersion:string, priority:number, sourceMediaPath:string) : Promise<CRS.ResponseWrapper<CRS.RSAddApplication>> {
        return new Promise<CRS.ResponseWrapper<CRS.RSAddApplication>>((resolve, reject)=> {
            this.applicationService.addApplication(this.dataContext.id, productName, productVersion, priority, sourceMediaPath)
                .then((response)=> {
                    resolve(response);
                }, (error)=> {
                    reject(error);
                })
        });
    }

    public deleteRule(ruleId:string) : Promise<boolean> {
        return new Promise<boolean>((resolve,reject)=> {
            this.projectService.removeProjectRule(ruleId).then(v=> {
                this.dataContext.refreshCurrent();
                resolve(true);
            },(err)=> {
                reject(false);
            });
        });
    }

    public createRule(ruleType:number, serialisedRule:string) : Promise<CRS.RSCreateProjectRule>{
        return new Promise<CRS.RSCreateProjectRule>((resolve, reject)=>{
            this.projectService.createProjectRule(this.dataContext.id, ruleType, serialisedRule).then((response)=>{
                this.dataContext.refreshCurrent();
                resolve(response.data);
            }, (err)=>{
                resolve(null);
            });
        });
    }

    public updaterule(ruleId:string, serialisedRule:string) : Promise<boolean>{
        return new Promise<boolean>((resolve,reject)=> {
            this.projectService.updateProjectRule(ruleId, serialisedRule).then(ret=> {
                this.dataContext.refreshCurrent();
                resolve(true);
            }, (err)=>{
                resolve(false);
            });
        });
    }

    public updateName(value:string) {
        this.projectService.updateProjectName(this.dataContext.id, value).then(()=> {
            this.dataItem.name= value;
            this.eventService.__projectDetailsUpdateRequestTrigger();
        });
    }

    public updateDescription(value:string) {
        this.projectService.updateProjectDescription(this.dataContext.id, value).then(()=> {
            this.dataItem.description=value;
            this.eventService.__projectDetailsUpdateRequestTrigger();
        });
    }

    public updateStorageAccessKey(value:string) {
        this.projectService.updateProjectStorageAccessKey(this.dataContext.id, value).then(()=> {
            this.dataItem.storageAccessKey=value;
            this.eventService.__projectDetailsUpdateRequestTrigger();
        });
    }

    public updateImage(imageType:number, imageData: string) {
        this.projectService.updateProjectImage(this.dataContext.id, imageType, imageData).then((response)=>{
            if (imageType == Constants.IMAGE_TYPE_DEFAULT)
                this.dataItem.imageId=response.data.imageId;
            else
                this.dataItem.customerReportingImageId = response.data.imageId;
            this.eventService.__projectDetailsUpdateRequestTrigger();
        });
    }

    public updateXCheckTrackedOperatingSystems(item: TrackedOperatingSystem) : Promise<boolean> {
        return this.projectService.updateXCheckTrackedOperatingSystems(this.dataContext.id, item.operatingSystemId, item.checkpoint, item.isChecked, item.originalTestEnabled, item.msixTestEnabled)
    }

    public updateProjectNotes(value:string) {
        this.updateRule("projectNotesRuleId", Enum.ProjectRuleType.ProjectNotes, value);
    }
    public updateShowEffortInAppCompatReport(value:string): void {
        this.updateRule("showEffortInAppCompatReportRuleId", Enum.ProjectRuleType.ShowEffortInAppCompatReport, value);
    }
    public updateAppIdCustomisations(value:string) : void {
        this.updateRule("appIdCustomisationsRuleId", Enum.ProjectRuleType.AppIdCustomisations, value);
    }
    public updateXCheckImageToolsKey(value:string) : void {
        this.updateRule("xcheckImageToolsKeyRuleId", Enum.ProjectRuleType.XCheckImageToolsKey, value);
    }
    public updateDisableAutomaticDetectedDependencyInstall(value:string):void {
        this.updateRule("disableAutomaticDetectedDependencyInstallRuleId", Enum.ProjectRuleType.DisableAutomaticDetectedDependencyInstall, value);
    }
    public updateInstallCommandFiles(value:string) :void {
        this.updateRule("installCommandFilesRuleId", Enum.ProjectRuleType.InstallCommandFiles, value);
    }

    public updateRuleGeneric(rule: CRS.RSProjRuleInfo) {
        if (!this.dataContext.isReady) 
            return;
        var pri = <CRS.RSProjRuleInfo>this.dataContext.item().data[rule.propertyName];
        var val = null;
        switch(rule.valType)
        {
            case Constants.VALTYPE_STRING:val=rule.value;break;
            case Constants.VALTYPE_BOOL:val=String(rule.boolValue?"true":"false");break;
            case Constants.VALTYPE_NUMBER:val =String(rule.numValue);break;
            default:throw `Invalid value type. Expecting [0-2] got ${rule.valType}`;
        } 
        if (val=="null" || val == undefined)
            val =null;
        let mod:boolean=false;
        if (val) {
            if (this.utilService.isEmptyOrEmptyGuid(pri.ruleId))
                this.projectService.createProjectRule(this.dataContext.id, rule.code, val).then((resp)=> {
                    pri.ruleId = resp.data.projectRuleId;
                    this.eventService.__projectDetailsUpdateRequestTrigger();
                });
            else
                this.projectService.updateProjectRule(pri.ruleId, val).then((resp)=> {
                    this.eventService.__projectDetailsUpdateRequestTrigger();
                })
        }
        else if (!val && !this.utilService.isEmptyOrEmptyGuid(pri.ruleId)) {
            this.projectService.removeProjectRule(pri.ruleId).then((ret)=> {
                pri.ruleId=this.utilService.getEmptyGuid();
                this.eventService.__projectDetailsUpdateRequestTrigger();
            });
        }
    }

    public updateWorkflowStageList(values:Array<string>) {
        var vjoined = values.join(';');
        this.updateRule("workflowStagesRuleId", Enum.ProjectRuleType.WorkflowStageList, vjoined);
        this.workflowStagesSplit = vjoined.replace(/;/g, '\n');
    }

    public updateMsixManifestModifications(value:string) : void {
        this.dataItem.msixManifestModifications.value = this.collapseSplitString(value);
        this.updateRuleGeneric(this.dataItem.msixManifestModifications);
    }

    public updateMsixCertificatePassword(value:string) : void {
        this.projectService.updateCertificatePassword(this.dataContext.id, value);
    }
    
    public createVirtualMachineFromBaseImage(imageId:string) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=> {
            this.conversionService.createVirtualMachineFromBaseImage(this.dataContext.id, imageId).then((ok)=> {
                resolve(true);
                this._onCheckVirtualMachineCreated.next();
            }, (err)=> {
                reject(err);
            });
        });

    }

    public getTrackedOperatingSystems() : Array<TrackedOperatingSystem> {

        if (!this.dataContext.isReady)
            return null;

        var lst = [];
        this.dataItem.trackableOperatingSystemConfigurations.forEach((x)=>{
            let tos = new TrackedOperatingSystem(x.operatingSystemId, x.checkpoint, x.origin);
            lst.push(tos);
            var osItem = this.dataItem.operatingSystems.filter(os=>x.operatingSystemId == os.id);
            if (osItem.length == 1) {
                var osItem0 = osItem[0];
                var tlst = osItem0.trackedItems.filter(y=>y.checkpoint == x.checkpoint);
                if (tlst.length == 1) {
                    var t0 =tlst[0];
                    tos.isChecked = true;
                    tos.originalTestEnabled = t0.originalTestEnabled;
                    tos.msixTestEnabled = t0.msixTestEnabled;
                }
            }
        });
        return lst;
    }

    public getWindowsUpdates() : Promise<Array<CRS.RSWindowsUpdate>> {
        return new Promise<Array<CRS.RSWindowsUpdate>>((resolve, reject) => {
            this.conversionService.getWindowsUpdates(this.dataContext.id).then((response) => {
                resolve(response.data.items);
            }, (err)=> {
                reject(err);
            });
        });
    }

    public retireWindowsUpdate(update: CRS.RSWindowsUpdate) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=> {
            this.conversionService.retireWindowsUpdate(update.fullId).then((ret)=> {
                resolve(ret);
            }, (err)=> {
                reject(err);
            });
        });
    }

    public getXCheckVirtualMachines() : Promise<Array<CRS.RSXCheckVirtualMachine>> {
        return new Promise<Array<CRS.RSXCheckVirtualMachine>>((resolve, reject) =>{
            this.conversionService.getXCheckVirtualMachines(this.dataContext.id).then((response)=> {
                resolve(response.data.items);
                this._onXCheckVirtualMachinesLoaded.next(response.data.items.length>0);
            }, (err)=> {
                reject(err);
            });
        });
    }

    public deleteVirtualMachineImage(id:string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=>{
            this.conversionService.deleteVirtualMachineImage(id).then((s)=>{
                resolve(true)
            }, (err)=> {
                reject(err);
            });
        });
    } 

    public importXCheckImage(id:string, name:string, year:number, month:number) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=> {
            this.conversionService.importXCheckImage(id, name, year, month).then((s)=> {
                resolve(true);
            }, (err)=> {
                reject(err);
            })
        });
    }

    private _outputTypes : Array<OutputItemInfoProj> =null;
    public get outputTypes() : Array<OutputItemInfoProj> {
        if (!this._outputTypes){
            this._outputTypes = this.dataItem.projectOutputItems.map((x)=> {
                return new OutputItemInfoProj(this, this.utilService, x.type, Enum.OutptType.Html);
            });
        }
        return this._outputTypes;
    }

    public getUpdateDate(platformNumber:number): YrMon {
        var plat = this.dataItem.platforms.filter((p)=>p.id == platformNumber)[0];
        if (plat.updateDate) {
            let year = parseInt(plat.updateDate.substring(3));
            let month = this.utilService.getMonthIndex(plat.updateDate.substring(0,3)) + 1;
            return new YrMon(year,  month);
        }
        return null;
    }

    public getNoteTypes() : Array<AppNote> {
        if (!this.dataContext.isReady)
            return [];
       
        return this.dataItem.applicationNoteTypes.map((nt) => {
            return new AppNote(nt.id, nt.name, "", nt.cls, nt.readonly);
        });
    }

    public get dataItem(): CRS.RSGetProject{
        return this.dataContext.isReady ? this.dataContext.item().data : null;
    }

    public updatePlatform(platformGuid:string, platDefn: PlatformDefn) {
        this.projectService.updatePlatformDefinition(
            platformGuid,platDefn.osFrom, 
            platDefn.osTo, 
            platDefn.virtualisationTechnology, 
            platDefn.office, 
            platDefn.dotnet,
            "", 
            platDefn.browser, 
            platDefn.monthlyUpdate).then(()=> {
            this._onPlatformModified.next(platDefn);
       }).then(()=> {
           this.dataContext.refreshCurrent();
       }) ;

    }

    public hasMsixPublisherDetails() : string {
        return this.dataContext.isReady && this.dataItem.msixPublisherName.value;
    }

    private cnt:number=0;
    public getNotifications() : Array<NotifyItem> {
        let m = localStorage.getItem(Constants.LOCALSET_NOTIFY_MIN) ? parseInt(localStorage.getItem(Constants.LOCALSET_NOTIFY_MIN)) : 0;
        let newm: number = m;
        if (this.dataContext.isReady && this.dataItem.notifyItems) {
            let items = this.dataItem.notifyItems.filter(n=>n.index > m); 
            if (items.length>0) {
                let ret: Array<NotifyItem>=[];
                items.forEach((item) => {
                    ret.push(new NotifyItem(item.text, item.hashCode));
                    if (item.index>newm)
                        newm=item.index;
                });
                localStorage.setItem(Constants.LOCALSET_NOTIFY_MIN, String(newm));
                return ret;
            }
        }
        return null;
    }

    private mainLoadPre() {
        this.checkInitialise();
        this._xcheckMetrics=null;
        this._customRuleSets=null;
        this._projectNotes=null;
        this._outputTypes=null;
        this._customRuleSets = {};
    }

    private mainLoad(id:string,refreshOnly:boolean) {
        return this.projectService.getProject(id,refreshOnly);
    }

    private mainLoadOk() {
        this._platformNumber = this.dataItem ? this.dataItem.platforms[0].id: 0;
        this.platformService.setCurrent(this.dataContext.id, this._platformNumber);
        this._projectNotes = new ProjectNotes(this);
        this._projectNotes.initialise();
        this.msixManifestModificationsSplit = this.dataItem? this.expandSplitString(this.dataItem.msixManifestModifications.value): null;
        if (this.dataItem)
            this.workflowStagesSplit = this.dataItem.workflowStages.replace(/;/g, '\n');
        this.publishCommandsSplit={};
        if (this.dataItem) {
            this.publishCommandsSplit['DFLT'] = this.expandSplitString(this.dataItem.publishingCommands.value);
            this.publishCommandsSplit['MSIX'] = this.expandSplitString(this.dataItem.publishingCommandsMsix.value);
            this.publishCommandsSplit['VIRT'] = this.expandSplitString(this.dataItem.publishingCommandsVirtualisation.value);
            this.publishCommandsSplit['EXE'] = this.expandSplitString(this.dataItem.publishingCommandsExe.value);
            this.publishCommandsSplit['LIQD'] = this.expandSplitString(this.dataItem.publishingCommandsLiqd.value);
        }
        this.projectService.getMsixBundles().then((response)=> {
            this.msixBundles = response.data.items;
        })
        if (this.dataItem) {
            this.projectService.getXCheckMetrics(this.dataItem.id).then((response)=>{
                this._xcheckMetrics = response.data;
                this._onXCheckMetricsLoaded.next(true);
            });
        };
        this.userCacheService.syncCurrentServer(this.utilService.serverId);
        var acts = this.utilService.navigation.getOne("ACTS");
        if (acts)
            acts.alert = ()=> {return this.dataItem && this.dataItem.conversionTaskInteractionRequired; };
    }

    private partialRefresh(response: CRS.ResponseWrapper<CRS.RSGetProject>) {
        this.getXCheckVirtualMachines();
        this.processImportProgressions(response.data);
        if(response.data)
            this.dataItem.vmPreparationScriptErrors=response.data.vmPreparationScriptErrors;
    }

    private updateRule(ruleId:string, ruleType:Enum.ProjectRuleType, v:string) {
        if (!this.dataContext.isReady) 
            return;
        if (v=="null" || v == undefined)
            v=null;

        let mod:boolean=false;
        if (v) {
            if (this.utilService.isEmptyOrEmptyGuid(this.dataContext.item().data[ruleId]))
                this.projectService.createProjectRule(this.dataContext.id, ruleType, v).then((resp)=> {
                    this.dataContext.item()[ruleId] = resp.data.projectRuleId;
                    this.eventService.__projectDetailsUpdateRequestTrigger();
                });
            else
                this.projectService.updateProjectRule(this.dataContext.item().data[ruleId], v).then((resp)=> {
                    this.eventService.__projectDetailsUpdateRequestTrigger();
                })
        }
        else if (!v && !this.utilService.isEmptyOrEmptyGuid(this.dataContext.item().data[ruleId])) {
            this.projectService.removeProjectRule(this.dataContext.item().data[ruleId]).then((ret)=> {
                this.dataContext.item()[ruleId]=null;
                this.eventService.__projectDetailsUpdateRequestTrigger();
            });
        }
           
    }

    private getReportingConfigOptions(data: CRS.RSGetReportingConfigurations) : Array<any> {
        let arr =[];
        arr.push({value:'[NULL]', text:"Default"});
        data.items.forEach((v,i)=>arr.push({value:v, text:v}));
        return arr;
    }

    private getErrorType(errorText:string) : Enum.ProjectErrorType {
        if (!errorText)
            return Enum.ProjectErrorType.None;
        if (this.utilService.isEmptyAny(errorText))
            return Enum.ProjectErrorType.None;
        if (errorText.indexOf("QProjectNotFoundException"))
            return Enum.ProjectErrorType.ProjectNotFound
        return Enum.ProjectErrorType.Unknown;
    }

    private _initialised:boolean=false;
    private checkInitialise() {
        if (this._initialised)
            return;
        if (!this.authService.isLoggedIn)
            return;
        if (!this.utilService.serverId)
            return;

        this._initialised=true;

        this.projectService.getReferenceData().then((response)=> {
            this.vhds = response.data.vhds.items;
            this.reportingConfigurationTypes = this.getReportingConfigOptions(response.data.reportingConfigurations);
            this.readmeDocumentationScripts = response.data.readmeDocumentationScripts.items;
            this.xcheckAvailableVMs = response.data.xcheckAvailableVms.items;
        }).catch((err)=> {
            this.errorService.show(Constants.ERRTYPE_HTTP, err.message, err.statusText, err.status);
        });
    }
 
    private collapseSplitString(value:string) : string{
        return value.replace(/\r/g, '').replace(/\n/g,';');
    }

    private expandSplitString(value:string) : string {
        return (value) ? value.split(';').join("\r\n") : "";
    }

    private processImportProgressions(data:CRS.RSGetProject) {
        if (!data.applicationImportProgressions)
            return;
        var apps = Object.assign({}, ...this.dataItem.applications.map((x)=>({[x.id]: x})));
        data.applicationImportProgressions.forEach((ip)=>{
            var a0 = <CRS.RSApplication>apps[ip.applicationId];
            if (a0) {
                a0.importPercentComplete = ip.percentage;
                if (ip.percentage>0)
                    a0.isPending=false;
            }
        });
    }
}