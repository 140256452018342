import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { ApplicationService } from '../../api/SVC';
import { AppNote } from '../../model/AppNote';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';

@Component({
  selector: 'project-application-notes',
    templateUrl: './project-application-notes.html',
})
export class ProjectApplicationNotes implements OnInit, OnDestroy {

  constructor(
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService, 
      private applicationService: ApplicationService,
  ) { }

  public notes: Array<AppNote> = [];

  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
    this.loadNotes();
    this.subscriptions.push( this.currentProject.dataContext.onLoaded().subscribe(()=> {
      this.loadNotes();
    }));
    this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe(()=> {
      this.loadNotes();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady;
  }

  public itemChanged = (args:INPUTEDITARGS) : void => {
    this.applicationService.setApplicationNote(this.currentApplication.dataContext.id, args.context, args.value);
  };

  private _notesLoaded:boolean=false;
  private loadNotes() {
    if (this._notesLoaded)
      return;
    this._notesLoaded=true;
    this.notes= this.currentProject.getNoteTypes();
    this.notes.forEach((n)=> {
        var lst = this.currentApplication.dataItem.notes.filter(x=>x.id == n.id);
        if (lst.length==1) {
          var l0 = lst[0]
          n.value=l0.value;
        }
    });
  }
}
