import { Component } from '@angular/core';
import { ErrorService } from '../svc/errorService';
import { UtilService } from '../svc/utilService';
import { SpineError } from '../model/SpineError';
import { Constants } from '../api/Constants';
import { NavigationService } from '../svc/navigationService';

@Component({
    selector: Constants.ROUTE_ERROR,
    templateUrl: './error-view.html',
    styleUrls:['./error-view.scss']
  })
 
export class ErrorView {

    constructor(
        private navigation: NavigationService,
        private utilService: UtilService,
        private errorService: ErrorService
    ) {
        var code = null;
        if (this.errorService.currentError)
            code = this.errorService.currentError.code;

        this.error = this.getErrorFromCode(this.errorService.errorCode);
        this.isnotfound=code===Constants.ERROR_CODE_NOT_FOUND;
        this.isauth=code===Constants.ERROR_CODE_AUTH;
    }

    public error: SpineError;
    public iserror: boolean;
    public isnotfound: boolean;
    public isauth: boolean;

    public goHome() {
        this.navigation.goHome();
    }

    public logout() {
        this.utilService.signOut();
//        this.$state.go('loginView');
    }

    public isServerError() : boolean {
        return this.error.title == Constants.ERRTYPE_HTTP;
    }

    private getErrorFromCode(code:string) : any {

        if (code === Constants.ERROR_CODE_NOT_FOUND) {
            let err = new SpineError(
                'Page not found', 
                'The page you were trying to open doesn\'t exist or has been moved',
                'Press home to return to the dashboard',
                null);
            err.code = code;
            return err;
        } else if (code === Constants.ERROR_CODE_AUTH) {
            let err = new SpineError(
                'Unauthorized', 
                'You don\'t have permission to see/do that',
                'May be your session has expired or is wrong?',
                null);
            err.code = code;
            return err;
        } else {
            let err = this.errorService.getCurrentError();
            return err;
        }

    }
}