import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Constants } from "../../api/Constants";
import { MenuService } from "../../svc/menuService";
import { NavigationService } from "src/app/svc/navigationService";
import { INPUTEDITARGS } from "../../model/INPUTEDITARGS";

import * as CRS from "../../api/CRS";
import * as SVC from "../../api/SVC";
import * as STHS from "../../svc/stateHelperService";

@Component({
    selector: Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL,
    templateUrl: "./admin-assessment-groups-detail.html",
    styleUrls: ["../../shared/css/admin.scss", "../../shared/css/view.scss"]
})
export class AdminAssessmentGroupsDetail implements OnInit {

    public assessmentGroup: CRS.RSAdminGetAssessmentGroup;
    public selectedGroupCheckId: string = null;

    public stateHelperInstance: STHS.StateHelperInstance;

    constructor(
        private menuService: MenuService,
        private navigation: NavigationService,
        private route: ActivatedRoute, 
        public stateHelperService: STHS.StateHelperService,
        private xadminService: SVC.AdminService,
    ) {}

    ngOnInit() {
        this.stateHelperInstance = this.stateHelperService.register(Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL);

        // Fetch page data
        this.update();
    }

    private update() {
        this.route.params.subscribe(
            params => {
                // Get group ID from route
                const assessmentGroupId = params[Constants.ROUTE_ASSESSMENT_GROUP_ID]

                // Reset selected group and show loading indicator
                this.assessmentGroup = null;

                // Fetch group data
                this.xadminService.adminGetAssessmentGroup(assessmentGroupId).then((response) => {
                    this.assessmentGroup = response.data;
                    this.menuService.refreshAll();
                }, () => {
                    console.error("Server data couldn't be loaded");
                });
            }
        )
    }

    public updateGroupId(idInputArgs: INPUTEDITARGS) {
        console.log("// TODO: Implement group ID update", { idInputArgs })
    }

    public updateGroupName(nameInputArgs: INPUTEDITARGS) {
        console.log("// TODO: Implement group name update", { nameInputArgs })
    }

    // Open assessment group check details
    public openCheck(checkId: string) {
        this.selectedGroupCheckId = checkId;
        this.navigation.goAdminAssessmentGroupCheckFULL(this.assessmentGroup.id, checkId);
    }
}
