import { PollingService } from '../../svc/pollingService';
import { StylingService } from '../../svc/stylingService';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { DISOUser } from 'src/app/model/DISOUser';

import * as STHS from '../../svc/stateHelperService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';
import { DISOUserFeature } from 'src/app/model/DISOUserFeature';

@Component({
    selector: Constants.ROUTE_ADMINUSERDETAIL,
    templateUrl: './admin-user-detail.html',
    styleUrls: ['../../shared/css/view.scss', '../../shared/css/tables.scss', './admin-user-detail.scss']
  })
  
export class AdminUserDetail implements OnInit, OnDestroy {

    public user: DISOUser=null;
    public errorCode: number=0;
    public errorMsg:string="";

    constructor(
        public route: ActivatedRoute,
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
        private stylingService: StylingService,
    ) {
        
    }

    ngOnInit(): void {
        this.stylingService.waitForApiEndpoint(()=>this.start());
        this.stateHelperService.onStateChange().subscribe(()=>{
            this.update();
        })
    }

    ngOnDestroy(): void {
    }

    public isDataReady() : boolean {
        return this.user !=null;
    }

    public changeState(feature: DISOUserFeature) {
        if (!this.xadminService.adminUpdateUserFeature(this.user.item.id, feature.id, feature.hasFeature)) {
            feature.toggleFeatureState();
        }
    }

    private start() {
        this.update();
    }

    private update(): void {
          this.xadminService.adminGetUser(this.stateHelperService.details.userId).then((response) => {
            if (response.status.errorCode == 0 && response.data.item) {
                this.user = new DISOUser( response.data.item, response.data.featureTypes);
                this.errorCode=0;
                this.errorMsg = "";
            }
            else {
                this.errorCode = response.status.errorCode;
                this.errorMsg = response.status.message
            }

        }, () => {
            console.error('User data couldn\'t be loaded');
        });
    }

}

