export class Constants {

    public static JOBTYPE_REPACKAGE: number = 1;
    public static JOBTYPE_VIRTUALISE: number = 2;
    public static JOBTYPE_MSIX: number = 20;
    public static JOBTYPE_INTUNE: number = 21;
    public static JOBTYPE_MSIEXTRACT:number = 22;

    public static JOBTYPE_TEST:number = 1003;
    public static JOBTYPE_APPCOMPATREPORT:number = 3;
    public static JOBTYPE_FIX:number = 4;
    public static JOBTYPE_FILEADDED:number = 5;
    public static JOBTYPE_CREATEDRIVELINK:number = 6;
    public static JOBTYPE_FILEDELETED:number = 7;
    public static JOBTYPE_CUSTOMRULE_FIX:number = 8;
    public static JOBTYPE_SECURITYREPORT:number = 9;
    public static JOBTYPE_RESPONSETRANSFORM:number = 10;
    public static JOBTYPE_MSIGATHER:number = 11;
    public static JOBTYPE_EXTRACTTRANSFORM = 12;
    public static JOBTYPE_DISCOVERY = 15;
    public static JOBTYPE_MSIMODIFY = 16;
    public static JOBTYPE_VIRTUALISEPREREQ:number = 23;
    public static JOBTYPE_PORTFOLIOREPORT:number = 80;
    public static JOBTYPE_EVALUATIONREPORT:number = 81;
    public static JOBTYPE_INSTALLERDEPENDENCYREPORT = 84;
    public static JOBTYPE_CSVEXPORT:number = 90;
    public static JOBTYPE_CSVEXPORTAG:number = 91;
    public static JOBTYPE_CSVEXPORTSUMMARISED:number = 92;
    public static JOBTYPE_QUERYEXEC:number = 100;
    public static JOBTYPE_PUBLISHTEST:number = 33;
    public static JOBTYPE_MSIEDIT: number = 32;

    public static TESTINSTALL_SUBTYPE_BASIC:string ="Basic";
    public static TESTINSTALL_SUBTYPE_FIXED:string ="Fixed";
    public static TESTINSTALL_SUBTYPE_ROLLBACK:string ="Rollback";
    public static TESTINSTALL_SUBTYPE_UNINSTALL:string ="Uninstall";
    public static TESTINSTALL_SUBTYPE_RUNTIME:string = "Runtime";
    public static TESTINSTALL_SUBTYPE_REPACK:string = "Repackage";

    public static IMAGE_TYPE_DEFAULT = 0;
    public static IMAGE_TYPE_CUSTOMERREPORTING = 1;

    public static ERRTYPE_HTTP = "HTTP";

    public static RULE_GROUP_STANDARDS : number = 2;
    public static RULE_GROUP_QACHECKSHEET : number = 54;

    public static CONV_ACTION_DELIM:string ='_';

    public static STORAGE_REPORTSSELECTEDCHARTS : string ="projectReports-selected-charts";

    public static EVENT_PLATFORMEDITED: string = "platform-edited";
    public static EVENT_PROJECTIDCHANGED: string = "platformService.projectId.current";
    public static EVENT_PROJECTDETAILSUPDATEREQUEST: string = "data-projectDetails-update-rquest";

    public static POLLING_PROJECTLIST_INTVL:number= 50000;
    public static POLLING_PROJECTDETAILS_INTVL:number= 30000;
    public static POLLING_VMLIST_INTVL:number = 30000;
    public static POLLING_NOTIFICATIONS_INTVL:number= 30000;
    public static POLLING_TASKS_INTVL:number=15000;
    public static POLLING_PATCHCONFLICTS_INTVL:number= 20000;
    public static POLLING_APPLICATIONVIEW_INTVL:number= 20000;
    public static POLLING_ACTIONVIEW_INTVL:number=20000;
    public static POLLING_ADMINWINDOWSUPDATES_INTVL:number= 3000;
    public static POLLING_APPLICATIONHISTORY_INTVL:number=60000;
    public static POLLING_PROJECTPATCHES_INTVL:number = 30000;
    public static POLLING_DRIVEVIEW_INTVL:number=20000;
    public static POLLING_USERS_INTVL:number = 120000;
    public static POLLING_CLICKERPROGRESS_INTVL:number= 8000;
    

    public static POLLING_ID_APPLICATIONVIEW ='applicationView';
    public static POLLING_ID_APPLICATIONHISTORY = 'applicationHistory';
    public static POLLING_ID_ACTIONDETAILS = 'appActionDetails';
    public static POLLING_ID_ACTIONVIEW = 'actionView';
    public static POLLING_ID_ADMININSTALATIONS = 'adminInstallations';
    public static POLLING_ID_TASKMGR = 'taskmgr';
    public static POLLING_ID_ADMINACTIVITY = "adminActivity";
    public static POLLING_ID_ADMINWINDOWSUPDATES = 'adminWindowUpdates';
    public static POLLING_ID_ADMINUSERS = "adminUsers";
    public static POLLING_ID_CLICKERPROGRESS = "clickerProgress";
    public static POLLING_ID_NOTIFICATIONS = 'notifications';
    public static POLLING_ID_CONVERSIONTASK = 'conversionTask';
    public static POLLING_ID_DRIVEVIEW = 'driveView';
    public static POLLING_ID_PATCHCONFLICTS = 'patchConflicts';
    public static POLLING_ID_PROJECTDETAILS = 'projectDetails';
    public static POLLING_ID_PROJECTACTIONS = 'projectActions';
    public static POLLING_ID_PROJECTPATCHES = 'projectPatches';
    public static POLLING_ID_PROJECTLIST =  'projectList';

    public static ROUTE_PRJ_ID = "prjId";
    public static ROUTE_APP_ID = "appId";
    public static ROUTE_AG_ID = "agId";
    public static ROUTE_JOB_ID = "jobId";
    public static ROUTE_CHK_ID ="chkId";
    public static ROUTE_ACT_ID = "actId";
    public static ROUTE_TBL_ID = "tblId";

    public static ROUTE_PROJECTCREATE = "pnew";
    public static ROUTE_REMOTINGRELEASE ="rr";

    public static ROUTE_PROJECTDETAILS_MAIN = "prj";
    public static ROUTE_PROJECTAPPLICATIONLANDING_MAIN = "apx";
    public static ROUTE_PROJECTREPORTAG_MAIN = "ag";
    public static ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN ="aad";
    public static ROUTE_PROJECTREPORTCHECKDETAIL_MAIN = "ckd";
    public static ROUTE_PROJECTACTIONDETAILS_MAIN = "actx";
    public static ROUTE_PROJECTAPPLICATIONBROWSETABLE_MAIN="tbl";

    public static ROUTE_PROJECTDASHBOARD = "prj-dash";
    public static ROUTE_PROJECTDETAILS = Constants.ROUTE_PROJECTDETAILS_MAIN + "/:" + Constants.ROUTE_PRJ_ID;
    public static ROUTE_PROJECTAPPLICATIONS = "app";

    public static ROUTE_PROJECTDRIVES ="drv";
    public static ROUTE_PROJECTREPORTS ="rpt";
    public static ROUTE_PROJECTQUERIES = "qry";
    public static ROUTE_PROJECTSETTINGS = "set";
    public static ROUTE_PROJECTPATCHES = "pat";
    public static ROUTE_PROJECTACTIONS = "act";
    public static ROUTE_PROJECTXCHECK = "xchk"
    public static ROUTE_PROJECTACTIONDETAILS = Constants.ROUTE_PROJECTACTIONDETAILS_MAIN + "/:" + Constants.ROUTE_ACT_ID;
    public static ROUTE_PROJECTREPORTAG = Constants.ROUTE_PROJECTREPORTAG_MAIN +"/:" + Constants.ROUTE_AG_ID;
    public static ROUTE_PROJECTREPORTCHECKDETAIL = Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN + "/:" + Constants.ROUTE_CHK_ID;
    public static ROUTE_PROJECTSETTINGSGENERAL=  "gen";
    public static ROUTE_PROJECTSETTINGSVHDS ="vhd";
    public static ROUTE_PROJECTSETTINGSPUBLISH = "pub";
    public static ROUTE_PROJECTSETTINGSCONVERT = "conv";
    public static ROUTE_PROJECTSETTINGSSHARED = "shr";
    public static ROUTE_PROJECTSETTINGSREPORTING = "rpt";
    public static ROUTE_PROJECTSETTINGSXCHECK = "xch";
    public static ROUTE_PROJECTSETTINGSWORKFLOW = "wkf";
    public static ROUTE_PROJECTSETTINGSSCRIPTS = "scr";
    public static ROUTE_PROJECTQUERIESCUSTOM = "qcus";
    public static ROUTE_PROJECTQUERIESSTANDARDS = "qstd";
    public static ROUTE_PROJECTQUERIESCHECKSHEET = "qchk";

    public static ROUTE_PROJECTAPPLICATIONLANDING = Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN + "/:" + Constants.ROUTE_APP_ID;
    public static ROUTE_PROJECTAPPLICATIONDETAILS = "apg";
    public static ROUTE_PROJECTAPPLICATIONISSUES =  "iss";
    public static ROUTE_PROJECTAPPLICATIONACTIONS = "aac";
    public static ROUTE_PROJECTAPPLICATIONNOTES =   "not";
    public static ROUTE_PROJECTAPPLICATIONHISTORY = "his";
    public static ROUTE_PROJECTAPPLICATIONCOMPANIONS = "cmp";
    public static ROUTE_PROJECTAPPLICATIONQA = "qa";

    public static ROUTE_PROJECTAPPLICATIONBROWSE = "brws"
    public static ROUTE_PROJECTAPPLICATIOBROWSETABLE = Constants.ROUTE_PROJECTAPPLICATIONBROWSETABLE_MAIN + "/:" + Constants.ROUTE_TBL_ID;

    public static ROUTE_XCHECK_SHORTCUTS = "xsht";
    public static ROUTE_XCHECK_EVENTLOGS = "xevl";
    public static ROUTE_XCHECK_DISKUSAGE = "xdu";
    public static ROUTE_XCHECK_SERVICES = "xsvc";
    public static ROUTE_XCHECK_COM =  "xcom";
    public static ROUTE_XCHECK_DCOM = "xdcom";
    public static ROUTE_XCHECK_PRINTERS = "xprn";

    public static ROUTE_ADMINCHECK_ID = "checkId"
    public static ROUTE_ADMININSTALL_ID = "serverId";
    public static ROUTE_ADMINPATCHGROUPMONTH_ID = "pgId"
    public static ROUTE_ADMINPATCHGROUPITEM_ID = "kbId"
    public static ROUTE_CATEGORY_ID = "categoryId";
    public static ROUTE_ASSESSMENT_GROUP_ID = "assessmentGroupId";
    public static ROUTE_ASSESSMENT_GROUP_CHECK_ID = "assessmentGroupCheckId";
    public static ROUTE_USER_ID = "uid";

    public static ROUTE_ADMINDASHBOARD = "admin";

    public static ROUTE_ADMINCHECKLIBRARY = "checkLibrary";
    public static ROUTE_ADMINCHECKDETAIL_MAIN = "checkDetail";
    public static ROUTE_ADMINCHECKDETAIL = Constants.ROUTE_ADMINCHECKDETAIL_MAIN + "/:" + Constants.ROUTE_ADMINCHECK_ID;

    public static ROUTE_ADMIN_ACTIVITY = "activity";
    
    public static ROUTE_ADMININSTALLATIONS = "installations";
    public static ROUTE_ADMININSTALLATIONSITEM_MAIN = "installItem";
    public static ROUTE_ADMININSTALLATIONSITEM = Constants.ROUTE_ADMININSTALLATIONSITEM_MAIN + "/:" + Constants.ROUTE_ADMININSTALL_ID;
    public static ROUTE_ADMININSTALLATIONITEMLOG = "installItemLog";
    public static ROUTE_ADMININSTALLATIONITEMSTAT = "stat";

    public static ROUTE_ADMINPATCHGROUPS = "patchgroups";
    public static ROUTE_PATCHGROUP_MAIN = "patchgroup";
    public static ROUTE_PATCHGROUPMONTH = Constants.ROUTE_PATCHGROUP_MAIN + "/:" + Constants.ROUTE_ADMINPATCHGROUPMONTH_ID;
    public static ROUTE_PATCHGROUPITEMDETAIL_MAIN = "kb";
    public static ROUTE_PATCHGROUPITEMDETAIL = Constants.ROUTE_PATCHGROUPITEMDETAIL_MAIN + "/:" + Constants.ROUTE_ADMINPATCHGROUPITEM_ID;

    public static ROUTE_ADMINPATCHCATS = "patchcats";
    public static ROUTE_PATCHCATEGORYFILES_MAIN = "patchCatFiles";
    public static ROUTE_PATCHCATEGORYFILES = Constants.ROUTE_PATCHCATEGORYFILES_MAIN + "/:" + Constants.ROUTE_CATEGORY_ID;

    public static ROUTE_ADMINPATCHCVECATS = "patchcvecats";
    
    public static ROUTE_ADMINPROJECTS = "projects";
    
    public static ROUTE_ADMINASSESSMENTGROUPS = "assessmentGroups";
    public static ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL_SLUG = "assessmentGroupsDetail";
    public static ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL = Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL_SLUG + "/:" + Constants.ROUTE_ASSESSMENT_GROUP_ID;
    public static ROUTE_ADMIN_ASSESSMENT_GROUPS_CHECK_SLUG = "agCheck";
    public static ROUTE_ADMIN_ASSESSMENT_GROUPS_CHECK = Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_CHECK_SLUG + "/:" + Constants.ROUTE_ASSESSMENT_GROUP_CHECK_ID;

    public static ROUTE_ADMINWINDOWSUPDATES = "wu";
    public static ROUTE_ADMINMSGROUTES = "mr";
    public static ROUTE_ADMINTASKMGR = "tm";
    public static ROUTE_ADMINUSERS = "us";

    public static ROUTE_ADMINUSERDETAIL_MAIN = "usd";
    public static ROUTE_ADMINUSERDETAIL = Constants.ROUTE_ADMINUSERDETAIL_MAIN + "/:" + Constants.ROUTE_USER_ID;

    public static APP_PRIM_TEST = "T";
    public static APP_PRIM_PUBLISH = "P";
    public static APP_PRIM_RTC = "RTC";
    public static APP_PRIM_MSIMODIFY = "MM";
    public static APP_PRIM_DISC = "DISC";
    public static APP_PRIM_PUBLISHTEST = "PT";
    public static APP_PRIM_EDIT = "ED";
    
    public static APP_SUPP_NOTES ="N";
    public static APP_SUPP_HISTORY ="H";
    public static APP_SUPP_DOC = "D";
    public static APP_SUPP_RELATEDAPPS = "RA";
    public static APP_SUPP_ISSUES = "IS";
    public static APP_SUPP_PREREQUISITES = "PRE";
    public static APP_SUPP_QA = "QA";  
    public static APP_SUPP_COMPANIONS = "C"; 

    public static ROUTE_PROJECTAPPLICATIONACTIONSDETAIL = Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN + "/:" + Constants.ROUTE_JOB_ID;

    public static ROUTE_ERROR = "err";
    public static ROUTE_ERROR_404 = "pagenotfound";
    public static ROUTE_ERROR_SERVER_LOST = "nosvr";

    public static MENU_DELETE= "delete";
    public static MENU_DELETE_PROJECT = "delete-project";
    public static MENU_FIX = "fix";
    public static MENU_ABOUT = "about";
    public static MENU_HELP = "help";
    public static MENU_ADD_APPLICATION = 'add-application';
    public static MENU_ADD_APPLICATIONS_FROM_CSV = 'add-applications-from-csv';
    public static MENU_SET_APPLICATION_SOURCE_PATH = 'set-application-source-path';
    public static MENU_VIEW_PACKAGE = 'view-package';
    public static MENU_VIEW_PACKAGE_MULTI = "view-package-multi";
    public static MENU_UPDATE_APPCOMPAT_REPORT = "update-appcompat-report";
    public static MENU_INITIATE_DRIVE_SCAN ="initiate-drive-scan";
    public static MENU_INITIATE_DRIVE_SCAN_QUICK ="initiate-drive-scan-quick";
    public static MENU_QUEUE_IMPORT = "queue-import";
    public static MENU_VIEW_SAS_KEY = "view-sas-key";
    public static MENU_UPDATE_PLATFORM_NAME = "update-platform-name";
    public static MENU_ADD_SHARE = "add-share";
    public static MENU_CLEAR_ENDED_CONVERSIONS = "clear-ended-conversions";
    public static MENU_RESET_APPLICATION_ACTION = "reset-application-action";
    public static MENU_UNPUBLISH = "unpublish";
    public static MENU_REMOVE_RULE ="remove-rule";
    public static MENU_RESTORE_RULE ="restore-rule";
    public static MENU_CHANGE_PASSWORD = "change-password";
    public static MENU_VIEW_ACTIVITY = "view-activity";
    public static MENU_REMOVE_FAILED_APPS = "remove-failed-apps";
    public static MENU_CREATE_DRIVE_LINK = "create-drive-link";
    public static MENU_EXECUTE_QUERY = "execute-query";
    public static MENU_SAVE_CUSTOM_QUERY = "save-custom-query";
    public static MENU_IMPORT_MSI_EXTRACTS ="import-msi-extracts";
    public static MENU_PUBLISH_PACKAGE = "publish-package";
    public static MENU_IMPORT_STANDARDS_CHECKS = "import-standards-checks";
    public static MENU_TOGGLE_SHOW_FIXED_ISSUES ="toggle-show-fixed-issues";
    public static MENU_TOGGLE_SHOW_VMS_PANEL = "toggle-show-vms-panel";
    public static MENU_SELECT_CUSTOM_QUERY = "select-custom-query";
    public static MENU_REPORT_OPTIONS = "report-options";
    public static MENU_UPLOAD_APPLICATION = "upload-applications";
    public static MENU_CREATE_APP_PROFILE = "create-app-profile";
    public static MENU_DISABLE_ASSESSMENTGROUP = "disable-ag";
    public static MENU_ENABLE_ASSESSMENTGROUP = "enable-ag";
    public static MENU_DISABLE_CHECK = "disable-chk";
    public static MENU_ENABLE_CHECK = "enable-chk";
    public static MENU_IMPERSONATE = "impersonate";
    public static MENU_REFRESH_METRICS = "refresh-metrics";
    public static MENU_REMOTING_RESET = "remoting-reset";
    public static MENU_UPDATE_APPID = "update-appid";
    public static MENU_ADD = "add";
    public static MENU_ADMIN_PATCH_UPDATE_REQUEST = "admin-patch-update-request";
    public static MENU_ADMIN_SET_PATCH_GROUP_COMPLETE ="admin-set-patch-group-complete";
    public static MENU_ADMIN_PATCH_BULLETIN_CHECK ="admin-patch-bulletin-check";
    public static MENU_CREATE_VM = "create-vm";
    public static MENU_IMPORT_WINDOWS_UPDATE = "import-windows-update";
    public static MENU_BACK = "back";
    public static MENU_REMOTE_CONNECT = "remote-connect"
    public static MENU_ADD_PATCH_CATEGORY ="add-patch-category"
    public static MENU_CLEAR_CACHE = "clear-cache";
    public static MENU_CANCEL = "cancel";
    public static MENU_EVAL_RELEASE = "eval-release";
    public static MENU_HIDE_VIRTUAL_MACHINES_PANEL = "hide-virtual-machines-panel";
    public static MENU_CREATE_XCHECK_VIRTUAL_MACHINE = "create-xchk-virtual-machine";
    public static MENU_CREATE_XCHECK_PATCH_TEST = "create-xchk-patch-test";
    public static MENU_SAVE_PACKAGE = "save-package";
    public static MENU_PACKAGE_FIND = "package-find";
    public static MENU_PACKAGE_FIND_NEXT = "package-find-next";
    public static MENU_PACKAGE_FIND_PREV = "package-find-prev";
    public static MENU_PACKAGE_VIEW_SUMMARY_INFORMATION = "package-view-summary-information";
    public static MENU_ADMIN_ASSGRPS_BACK = "admin-assgrps-back";
    public static MENU_ADMIN_ASSGRPS_CHK_BACK = "admin-assgrps-chk-back";
    public static MENU_TASK_RELEASE = "task-release";
    public static MENU_IGNORE_ISSUES = 'ignore';

    public static ERROR_CODE_NOT_FOUND = "NotFound";
    public static ERROR_CODE_AUTH="Auth";

    public static LOCALSET_APPLICATIONS_ROUTE_LAST_USED = "app-route-last-used";
    public static LOCALSET_FIXED_ISSUES_VISIBLE = "fixed-issues-visible";
    public static LOCALSET_SHOW_EVAL_RESULTS_IN_README = "show-eval-results-in-readme";
    public static LOCALSET_APPLICATION_ISSUES_EXPANDED ="application-issues-expanded";
    public static LOCALSET_XCHECK_ACTION_TABID = "xcheck-action-tabId"
    public static LOCALSET_PROJECT_SUB = "project-sub";
    public static LOCALSET_PROJECT_SET_SUB = "project-set-sub";
    public static LOCALSET_SHOW_CLICKER_PANEL = "show-clicker-panel";
    public static LOCALSET_LAST_REFERENCED_PROJECT = "last-ref-project";
    public static LOCALSET_LAST_REFERENCED_SERVER = "last-ref-svr";
    public static LOCALSET_EXPAND_APP_HEADER = "expand-app-header";
    public static LOCALSET_NOTIFY_MIN = "notify-min";
    public static LOCALSET_PROJECT_SEARCH_TEXT = "project-search-text";
    public static LOCALSET_ACTIVITY_LIST_DURATION = "activity-list-duration";
    public static LOCALSET_LAST_ERROR = "last-error";
    public static LOCALSET_USE_ARP_PRODUCT_PROPS = "use-arp-product-props";
    public static LOCALSET_ADMIN_SUB = "admin-sub";
    public static LOCALSET_ADMININST_SUB = "admin-inst-sub";
    public static LOCALSET_LAST_ACTION= "last-action";
    public static LOCALSET_XCHECK_UPDATE_FILTER_STATE = 'xcheck-update-filter-state';
    public static LOCALSET_SHOW_VMS_PANEL = "show-vms-panel";
    public static LOCALSET_VIEW_EMPTY_TABLES ="view-empty-tables";
    public static LOCALSET_LAST_BROWSE_TABLE = "last-browse-table";
    public static LOCALSET_VIEW_INTERNAL_TABLES = "view-internal-tables";
    public static LOCALSET_CONTEXT_VIEWER = "context-viewer";
    public static LOCALSET_LAST_QUERY_ROUTE = "last-query-route";
    public static LOCALSET_MERGE_TRANSFORMS_DURING_PUBLISH = "merge-transforms-during-publish";
    public static LOCALSET_MSAL_TOKEN = "msal.idtoken";
    public static LOCALSET_MSAL_EXPIRESON = "msal.expireson";
    public static LOCALSET_ASSESSMENT_GROUP_CHARTS = "assessment-group-charts";
    public static LOCALSET_EXPANDED_ACTION_TREE_NODES = "expanded-action-tree-nodes";
    public static LOCALSET_APPGRIDMENU_HEADER_ROW_VISIBLE="gridmenu-hdr-row-visible";
    public static LOCALSET_DRIVESGRIDMENU_HEADER_ROW_VISIBLE="drives-gridmenu-hdr-row-visible";
    public static LOCALSET_OUTPUTACTIONID = "output-action-id";
    public static LOCALSET_EXPANDED_OUTPUT_ACTION_TREE_NODES = "expanded-output-action-tree-nodes";

    public static APP_GRID_MENU_CMD_LOAD_PROFILE ="load-grid-profile";
    public static APP_GRID_MENU_CMD_ADD_PROFILE = "add-grid-profile";
    public static APP_GRID_MENU_CMD_SHOW_FILTERS = "show-filters";
    public static APP_GRID_MENU_CMD_CLEAR_GROUPING = "clear-grouping";
    public static APP_GRID_MENU_CMD_TOGGLE_FILTER = "toggle-filter";

    public static ARGUMENT_UPDATE_TYPE_SILENT = 0;
    public static ARGUMENT_UPDATE_TYPE_NORMAL = 1;

    public static GRID_HDR_CMD_GROUP = "group";

    public static STATUS_PENDING=6;
    public static STATUS_SUCCESS=4;
    public static STATUS_FAIL=2;
    public static STATUS_INPROGRESS=1;
    public static STATUS_CANCELLED=3;
    public static STATUS_STARTED=0;

    public static ASSUREZIPSTATE_NOTREQUIRED = 0;
    public static ASSUREZIPSTATE_NOTDONE = 1;
    public static ASSUREZIPSTATE_DONE = 4;
    public static ASSUREZIPSTATE_FAIL = 2;
    public static ASSUREZIPSTATE_INPROGRESS = 6;
    public static ASSUREZIPSTATE_INPROGRESSHANDLED = 8;

    public static ACTION_BROWSE = "BRWSE";

    public static ACTION_XCHECK_SHT = "SHT";
    public static ACTION_XCHECK_EVL = "EVL";
    public static ACTION_XCHECK_DSK = "DSK";
    public static ACTION_XCHECK_SVC = "SVC";
    public static ACTION_XCHECK_COM = "COM";
    public static ACTION_XCHECK_DCOM = "DCOM";
    public static ACTION_XCHECK_PRT = "PRT";
    public static ACTION_XCHECK_FIR = "FIR";
    public static ACTION_XCHECK_ENV = "ENV";
    public static ACTION_XCHECK_SCHED = "SCHD";
    public static ACTION_XCHECK_COMPAT = "COMP";
    public static ACTION_XCHECK_DEFEX = "DFEX";

    public static CONV_EVTEXT_MANUALMOD_PREP = 1;
    public static CONV_EVTEXT_MANUALMOD_WAIT = 2;
    public static CONV_EVTEXT_MANUALMOD_DONE = 3;

    public static CTXT_VIEWER_PLATFORM = "PE";
    public static CTXT_VIEWER_WORKFLOW = "WF";

    public static GRID_ROW_HEIGHT = 34;

    public static ERR_JOB_NOT_FOUND = 801;
    public static ERR_APPLICATION_NOT_FOUND = 800;

    public static CLICKER_EXCHANGE_STATUS_CREATED = 0;
    public static CLICKER_EXCHANGE_STATUS_ACKNOWLEDGED = 1;
    public static CLICKER_EXCHANGE_STATUS_INITIALISING = 2;
    public static CLICKER_EXCHANGE_STATUS_RUNNING = 3;
    public static CLICKER_EXCHANGE_STATUS_RETRIEVINGOUTPUT = 4;
    public static CLICKER_EXCHANGE_STATUS_DONE = 5;

    public static SOURCE_AVAILABILITY_OK = 0;
    public static SOURCE_AVAILABILITY_MISSING = 1;
    public static SOURCE_AVAILABILITY_NOTDEFINED =2;

    public static VALTYPE_STRING = 0;
    public static VALTYPE_NUMBER = 1;
    public static VALTYPE_BOOL = 2;

    public static RULEINFO_FLAGS_COMMATOMULTI = "CommaToMulti";
}
