<mat-drawer-container autosize class="full-height-bs">

    <mat-drawer #drawer mode="over">    
        <div class="full-height-bs">
            <platform-editor [style.display]="contextViewer=='PE'? '': 'none'"  (onClose)="drawer.toggle(false)"></platform-editor> 
            <workflow-bar [style.display]="contextViewer=='WF'? '': 'none'" (onClose)="drawer.toggle(false)"></workflow-bar>
        </div>
    </mat-drawer>

    <div fxLayout="column" fxFlexFill fxLayoutAlign="start stretch">

         <div fxFlex="0 0 38px" class="sw-toolbar" fxLayout="row" fxLayoutAlign="start center">
            <project-menu fxFlex="none" *ngIf="isLoaded"></project-menu>
            <div class="sw-server" fxFlex="none">{{serverId}}</div>
            <breadcrumb-bar fxFlex="none"></breadcrumb-bar>
            <div fxFlex></div>
            <project-outputs-selector fxFlex="none" *ngIf="isLoaded && !isInError"></project-outputs-selector>
            <div fxFlex="none"><div class="sw-pad-small"></div></div>
        </div>

        <div fxFlex fxLayout='row' fxLayoutAlign="start stretch" fxFlexFill>
            <project-details-sidenav fxFlex="none" 
                [project]="projectDetails" [ngClass]='{"sw-side-nav-hide":!showSideNav}'
                (onDrawer)="drawer.toggle()"></project-details-sidenav>
            <spinner fxFlex *ngIf="!isLoaded && !isInError"></spinner>
            <div fxFlex fxLayout="column" *ngIf="isLoaded">
                <div fxFlex *ngIf="!isInError" style="border:solid 1px white"> <!--border solves extra scrollbar issue -->
                    <router-outlet></router-outlet> 
                </div>
            </div> 
            <project-error fxFlex *ngIf="isInError"></project-error>
        </div>

    </div>

</mat-drawer-container>