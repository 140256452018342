import { Injector } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { GridAssistant } from 'src/app/model/gridAssistant';
import { NavigationService } from 'src/app/svc/navigationService';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: Constants.ROUTE_ADMINUSERS,
    templateUrl: './admin-users.html',
    styleUrls: ['../../shared/css/view.scss', '../../shared/css/tables.scss']
  })
  
export class AdminUsers implements OnInit, OnDestroy {

    constructor(
        private injector: Injector,
        private navigation: NavigationService,
        private xadminService: SVC.AdminService,
    ) {
        
    }

    public gridAssist: GridAssistant<CRS.RSAdminGetUsers, CRS.RSAdminUserItem>;

    ngOnInit(): void {
        this.gridAssist = new GridAssistant<CRS.RSAdminGetUsers, CRS.RSAdminUserItem>(Constants.ROUTE_ADMINUSERS, this.injector);
        this.gridAssist.setColumns(   [         
                { id: 'userEmail',   name: 'Email',   field: 'userEmail',   sortable: true, width: 400 },
                { id: 'emailDomain', name: 'Domain', field: 'emailDomain', sortable: true, width: 200 },
                { id: 'lastLoginExpression', name: 'Last Login', field: 'lastLoginExpression', sortable: false, width: 100 },
                { id: 'servers', name: 'Servers', field: 'servers', sortable: true, width: 200 }
            ]
        );
        this.gridAssist.setDetailColumns([ "Email" ]);
        this.gridAssist.allowRowSelection((r)=>this.openUser(r));
        this.gridAssist.setUpdate(()=>this.update());
    }

    ngOnDestroy(): void {
        this.gridAssist.dispose();
    }

    public get selectedUserId(): string {
        return null;
        //return this.stateHelperService.details.userId;
    }

    public openUser(user: CRS.RSAdminUserItem) {
       
        if (user) {
            this.navigation.goAdminUser(user.id);
        }
    }

    private update(): void {
          this.xadminService.adminGetUsers().then((response) => {
            this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSAdminUserItem );
        }, () => {
            console.error('Server data couldn\'t be loaded');
        });
    }

}

