import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { AdminPatchGroupItem } from '../admin-patch-group-item/admin-patch-group-item';
import { AppCommand } from '../../model/AppCommand';
import { MenuService } from '../../svc/menuService';
import { NavigationService } from 'src/app/svc/navigationService';

import * as STHS from '../../svc/stateHelperService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: "admin-patch-kb",
    templateUrl: './admin-patch-kb.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/view.scss']
})

export class AdminPatchKB implements OnInit {

    public patchgroupId: string = '';
    public kbId: string = '';

    constructor(
        private navigation: NavigationService, 
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
        private menuService: MenuService
    ) {
        const urlValues = this.navigation.urlParts;
        this.patchgroupId = urlValues[5]
        this.kbId = urlValues[urlValues.length-1]
    }

    public stateHelperInstance: STHS.StateHelperInstance;
    public patchKB: CRS.RSAdminGetKB=null;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_PATCHGROUPITEMDETAIL);
        this.update();
        this.menuService.register(Constants.MENU_BACK, this.backCommand, `P-${Constants.ROUTE_PATCHGROUP_MAIN + "/" + this.patchgroupId}`);
    }

    ngAfterContentChecked(){
        const urlValues = this.navigation.urlParts;
        if(this.kbId != urlValues[urlValues.length-1]){
            this.kbId = urlValues[urlValues.length-1]
            AdminPatchGroupItem.updateSelectedServer(this.kbId)
         this.xadminService.adminGetKB(this.patchgroupId, this.kbId).then((response) => {
             this.patchKB = response.data;
        }, () => {
             console.error('Patch KB data couldn\'t be loaded');
        });
        }
    }

    private update(): void {
        AdminPatchGroupItem.updateSelectedServer(this.kbId)
         this.xadminService.adminGetKB(this.patchgroupId, this.kbId).then((response) => {
             this.patchKB = response.data;
        }, () => {
             console.error('Patch KB data couldn\'t be loaded');
        });
    }

    private backCommand: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            AdminPatchGroupItem.updateSelectedServer('')
            this.navigation.goAdminPatchGroupFULL(this.patchgroupId);
        }
    )

}
