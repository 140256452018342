import { Component, Input } from '@angular/core';

@Component({
    selector: "error-panel",
    templateUrl: './error-panel.html'
})
export class ErrorPanel {

    constructor(
    ) {
    }

    @Input('message') message: string  

}