import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { NavigationService } from 'src/app/svc/navigationService';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-details-sidenav',
  templateUrl: './project-details-sidenav.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectDetailsSideNav implements OnInit, OnDestroy {

  constructor(
      private navigation:NavigationService, 
      private stateHelperService: StateHelperService,
      private currentProject: CurrentProjectService,
      private utilService:UtilService) { }

  @Input('project') project : CRS.RSGetProject;
  @Output() onDrawer = new EventEmitter<boolean>();

  private subscriptions: Array<Subscription> =new Array<Subscription>();

  ngOnInit(): void {
      this.gotoSub();
      this.stateHelperService.onStateChange().subscribe(()=>this.gotoSub());
      this.utilService.onNavReady().subscribe(()=>this.gotoSub());
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((s)=>s.unsubscribe());
  }

    public get navItems(): Array<any> {
        if (this.utilService.navigation)
            return this.utilService.navigation.getAll();
        else
            return [];
    }
    
    public getClass(item: any) : string{
        var suffix = (this.stateHelperService.details.statePathFind(item.route)) ?"active":"inactive";
        return `sw-nav-option-${suffix}`;
    }

    public isReady() : boolean {
        return !this.utilService.isEmptyAny(this.project);
    }

    public disableClick(): boolean {
        return this.utilService.isEmptyAny(this.project);
    }

    public invoke(item:any)
    {
        localStorage.setItem(Constants.LOCALSET_PROJECT_SUB, item.id);
        var prjId = this.stateHelperService.details.projectId;
        this.navigation.goProjectSubFULL(prjId, item.route);
    }

    public isMaximised() :boolean {
        return this.stateHelperService.details.statePath.length < 4;
    }

    private gotoSub() {
        if (this.utilService.navigation && this.stateHelperService.details.isStatePathCurrent(Constants.ROUTE_PROJECTDETAILS_MAIN)) {
            var projSubId = localStorage.getItem(Constants.LOCALSET_PROJECT_SUB);
            if (!projSubId)
                projSubId = "APPS";
            var nav = this.utilService.navigation.getOne(projSubId);
            if (!nav || (nav.condition && !nav.condition()))
                nav =this.utilService.navigation.getOne("APPS");
            if (nav)
                this.invoke(nav);
        }
    }

    public conditionFilter(itemList: any[]): any[] {
        let result: any[] = [];
        itemList.forEach((v)=>{
            if (!v.condition || v.condition())
                result.push(v);
        })
        return result;
    }

    public onDrawerClick(value:string) {
        this.contextViewValue = value;
        this.onDrawer.emit(true);
    }
  
    public get contextViewValue(): string {
        return this.utilService.contextViewer;
    }

    public set contextViewValue(value:string) {
        this.utilService.contextViewer = value;
    }
    
}
