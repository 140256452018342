import { AppCommand } from '../../model/AppCommand';
import { MenuService } from '../../svc/menuService';
import { ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/api/Constants';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/svc/navigationService';
import { AdminCheckLibrary } from '../admin-check-library/admin-check-library';

import * as STHS from '../../svc/stateHelperService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: Constants.ROUTE_ADMINCHECKDETAIL,
    templateUrl: './admin-check-detail.html',
    styleUrls: ['../../shared/css/view.scss', '../../shared/css/tables.scss', '../../shared/css/forms.scss', '../../shared/css/core.scss']
  })

export class AdminCheckDetail implements OnInit{

    constructor(
        private route: ActivatedRoute, 
        private navigation: NavigationService,
        private xadminService: SVC.AdminService,
        private menuService: MenuService,
        private stateHelperService: STHS.StateHelperService
    ) {
        this.menuService.register(Constants.MENU_BACK, this.backCommand, `P-${Constants.ROUTE_ADMINCHECKLIBRARY}`);
    }

    public check: CRS.RSAdminGetCheck;
    public checkId: string = "";
    public stateHelperInstance: STHS.StateHelperInstance;

    ngOnInit() {
        this.stateHelperInstance = this.stateHelperService.register(Constants.ROUTE_ADMINCHECKDETAIL);
        this.update();
    }

    private update(): void {
        this.route.params.subscribe(params => {
            this.checkId = params.checkId;
            AdminCheckLibrary.updateSelectedLibrary(this.checkId)
            this.xadminService.adminGetCheck(this.checkId).then((response) => {
                this.check = response.data;
            }, () => {
                console.error('Server data couldn\'t be loaded');
            });
        }, () => {
            console.error('Check instance item data couldn\'t be loaded');
        });
    }

    public log(value) {console.log(value)}

    public openRule(rule: CRS.RSAdminCheckRule) : void {
        
    }

    public shouldMinimise() : boolean {
         return this.navigation.url !== ("/" + Constants.ROUTE_ADMINDASHBOARD + "/" + Constants.ROUTE_ADMINCHECKLIBRARY + '/' + Constants.ROUTE_ADMINCHECKDETAIL_MAIN + "/" + this.checkId);
    }

    private backCommand: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            AdminCheckLibrary.updateSelectedLibrary('');
            this.navigation.goAdminCheckLibrary();
        }
    )

}
