import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { YesNoDialog } from './dialogs/yes-no-dialog';
import { PromptDialog } from './dialogs/prompt-dialog';
import { SimpleDialog } from './dialogs/simple-dialog';
import { InputDialog } from './dialogs/input-dialog';
import { AdminAddPatchCategoryDialog } from './dialogs/admin-add-patch-category-dialog';

export enum MessageType {
    Error,
    Success,
    Info,
    Warning,
    Pre,
    YesNo,
    Help
  };

@Injectable({
    providedIn: 'root'
})
export class DialogService implements OnInit {

    constructor(private dialog: MatDialog) { }

    ngOnInit(): void {
    }

    public showYesNoMessage(title: string, texts: Array<string>) : Observable<boolean> {

        //let templateUrl: string = this.getTemplateUrl(type,null,null);
        let lthis=this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title:title,
            texts:texts,
            showCancel:false
        }
    
        return new Observable<boolean>((observer)=>{
            const dr = this.dialog.open(YesNoDialog, dialogConfig);
            dr.afterClosed().subscribe(value => {
                observer.next( <boolean>value);
            });
        });
    }

    public showPrompt(title: string, texts: Array<string>) {

//        let templateUrl: string = this.getTemplateUrl(type,help,prompt);
  //      let lthis=this;

        const dialogConfigPrompt = new MatDialogConfig();
        dialogConfigPrompt.disableClose = true;
        dialogConfigPrompt.autoFocus = true;
        dialogConfigPrompt.data = {
            title:title,
            texts:texts
        }
    
        this.dialog.open(PromptDialog, dialogConfigPrompt);
    }

    public showMessage(title: string, texts: Array<string>, type: MessageType, help:string, prompt?: boolean ) {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title:title,
            texts:texts
        }
    
        if (type == MessageType.Info)
            this.dialog.open(SimpleDialog, dialogConfig);
        else
            this.dialog.open(YesNoDialog, dialogConfig);
    }

    public showInfoMessage(title: string, texts: Array<string>, prompt?: boolean) {
        const p = prompt ? prompt : false;
        this.showMessage(title, texts, MessageType.Info, '', p)
      }


      public async showInputPrompt(title: string, texts: Array<string>) {

        //        let templateUrl: string = this.getTemplateUrl(type,help,prompt);
          //      let lthis=this;
                const dialogConfigPrompt = new MatDialogConfig();
                dialogConfigPrompt.disableClose = true;
                dialogConfigPrompt.autoFocus = true;
                dialogConfigPrompt.data = {
                    title:title,
                    texts:texts
                }
                const dr = this.dialog.open(InputDialog, dialogConfigPrompt);
                const result = await dr.afterClosed().toPromise();
                return result
        }

    public async showAddPatchCategory(title: string){
        const dialogConfigPrompt = new MatDialogConfig();
                dialogConfigPrompt.disableClose = true;
                dialogConfigPrompt.autoFocus = true;
                dialogConfigPrompt.data = {
                    title:title,
                }
                const dr = this.dialog.open(AdminAddPatchCategoryDialog, dialogConfigPrompt);
                const result = await dr.afterClosed().toPromise();
                return result
    }

}
