import { Component, Input, Injector } from '@angular/core';
import { AuthService } from '../../svc/authService';
import { UserCacheService } from '../../svc/userCacheService';
import { StylingService } from '../../svc/stylingService';
import { AboutCommand } from '../../commands/about';
import { HelpCommand } from '../../commands/help';
import { NavigationService } from 'src/app/svc/navigationService';
import { UtilService } from '../../svc/utilService';

import * as CRS  from '../../api/CRS';

@Component({
    selector: "heading-bar",
    templateUrl: './headingBar.html',
    styleUrls:['./headingBar.scss',  '../../shared/css/buttons.scss']
})
export class HeadingBar {

    constructor(
        private injector: Injector,
        private stylingService : StylingService,
        public authService: AuthService,
        public userCacheService: UserCacheService,
        public navigation: NavigationService,
        public utilService: UtilService,
    ) {
    }

    @Input() public user : CRS.RSGetUserInformation=null;

    public isAdminUser(): boolean {
        return this.utilService.privileges?.admin;
    };

    public isStylingReady() : boolean {
        return this.stylingService.isLoaded();
    }
    
    getHeaderBgColor(): string {
        return this.stylingService.getCustomHeaderBgColor();
    }

    getHeaderFgColor(): string {
        return this.stylingService.getCustomHeaderFgColor();
    }
    
    getHeaderImage() : string {
        if (this.stylingService.hasCustomHeaderImage())
          return this.stylingService.getCustomHeaderImageData();
        else
          return "img/header/logoRDark.png";
    }
    
    getHeaderImageClass(): string {
        return (this.stylingService.hasCustomHeaderImage()) ? null :"header-logo-img";
    }
    
    showHelp() {
        var ac = new HelpCommand(this.injector);
        ac.command.execute();
    }
    
    showAbout() {
        var ac = new AboutCommand(this.injector);
        ac.command.execute();
    }

}