import { Injector, Component, OnInit, OnDestroy } from '@angular/core';
import { Column } from 'angular-slickgrid';
import { Constants } from 'src/app/api/Constants';
import { GridAssistant } from 'src/app/model/gridAssistant';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: "admin-msg-routes",
    templateUrl: './admin-msg-routes.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss']
})

export class AdminMessageRoutes implements OnInit, OnDestroy {

    constructor(
        private injector: Injector,
        private xadminService: SVC.AdminService,
    ) {
    }

    public gridAssist: GridAssistant<CRS.RSAdminGetMessageRoutes, CRS.RSAdminMessageRouteItem>;

    ngOnInit() {
        this.gridAssist = new GridAssistant<CRS.RSAdminGetMessageRoutes,CRS.RSAdminMessageRouteItem>(Constants.ROUTE_ADMINMSGROUTES, this.injector);
        this.gridAssist.setColumns( this._coreCols);
        this.gridAssist.setUpdate(()=>this.update());
    }

    ngOnDestroy(): void {
        this.gridAssist.dispose();
    }

    private _coreCols: Array<any> = [
        { id: 'category', name: 'Category', field: 'category', sortable: true, width: 100 },
        { id: 'id', name: 'Id', field: 'id', sortable: true, width: 200 },
        { id: 'execType', name: 'ExecType', field: 'execType', sortable: true, width: 300 }
    ];

    private update(): void {
        this.xadminService.adminGetMessageRoutes().then((response) => {
            this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSAdminMessageRouteItem );
            var cols = [];
            this._coreCols.forEach(x=>cols.push(x));
            response.data.servers.forEach(x=>cols.push({id:`svr${x}`, name: x, field: `svr${x}`,width:80, formatter: (r,c,v,cd,dc)=> { return this.getServerVal(dc,cd) } }));
            this.gridAssist.setColumns(cols);
        }, () => {
            console.error('Message route data couldn\'t be loaded');
        });
    }

    private getServerVal(dc:any, c: Column) {
        if (dc.category == "Timer") {
            var svrId = (<string>c.id).substring(3); 
            var slst = dc.states.filter(x=>x.serverId == svrId);
            var s0 = (slst.length ==0)?null:slst[0];
            var val= s0?.nextDue;
            if (s0?.isOverdue)
                return `<span class="sw-error">${val}</span>`;
            else
                return `<span>${val}</span>`;
        }
        return null;
    }
}
