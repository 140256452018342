import { AppCommand } from '../../model/AppCommand';
import { MenuService } from '../../svc/menuService';
import { DialogService } from '../../svc/dialogService';
import { CCGState } from '../../model/PatchExt';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { take} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { AuthService } from 'src/app/svc/authService';
import { NavigationService } from 'src/app/svc/navigationService';
import { StylingService } from 'src/app/svc/stylingService';

import * as STHS from '../../svc/stateHelperService';
import * as UTIL from '../../svc/utilService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';


@Component({
    selector: "admin-patch-groups",
    templateUrl: './admin-patch-groups.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss']
})

export class AdminPatchGroups implements OnInit {

    static selectedPatchgroup: string = '';
    public classReference = AdminPatchGroups;

    constructor(
        private navigation: NavigationService, 
        private xadminService: SVC.AdminService,
        private utilService: UTIL.UtilService,
        private stateHelperService: STHS.StateHelperService,
        private menuService: MenuService,
        private dialogService: DialogService,
        private http:HttpClient,
        private authService: AuthService,
        private stylingService: StylingService
    ) {
        this.menuService.register(Constants.MENU_ADMIN_PATCH_UPDATE_REQUEST, this.patchUpdateRequestCommand, Constants.ROUTE_ADMINPATCHGROUPS);
        this.classReference.selectedPatchgroup = ''
    }

    public patchGroups: CRS.RSAdminGetPatchGroups = null;
    public stateHelperInstance: STHS.StateHelperInstance;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_ADMINPATCHGROUPS);
        this.stylingService.waitForApiEndpoint(()=>this.update());
    }

    public getClass(ccg: CRS.RSAdminCheckCandidateGrp) : string {
        let ret = "sw-ccg-state-" + ccg.state;
        if (ccg.checkGenerated)
            ret += " sw-ccg-checkgen";
        return ret;
    }

    private update(): void {

        this.xadminService.adminGetPatchGroups().then((response) => {
            this.patchGroups = response.data;
        }, () => {
            console.error('Patch group data couldn\'t be loaded');
        });
    }

    public openPatchGroup(pg: CRS.RSPatchGroup) {
        this.classReference.selectedPatchgroup = pg.id;
        this.navigation.goAdminPatchGroupFULL(pg.id);
    }

    static updateSelectedPatchgroup(id: string) {
        this.selectedPatchgroup = id;
    }

    public getExportUrl(pg : CRS.RSPatchGroup) : string {
        return this.utilService.getAPIUrl( "Admin/AdminExportPatchGroup?PatchGroupId=" + pg.id + "&Auth=" + this.authService.authToken);
    }

    public openExportCsv($event: Event, pg: CRS.RSPatchGroup) : void {

        var bearerAuth = `Bearer ${this.authService.authToken}`;
        var urlBase = this.utilService.getAPIUrl( "Admin/AdminExportPatchGroup?PatchGroupId=" + pg.id);

        const httpOptions = {
            headers: new HttpHeaders(
                { Authorization: bearerAuth, 'Content-Type': 'text/csv' }
            ),
            responseType: 'blob' as 'blob'
        };

        this.http.get( urlBase, httpOptions).subscribe(data=>{
            var file = new Blob([data], {type: 'text/csv'});
            var fileURL = URL.createObjectURL(file);
            var downloadLink = document.createElement("a");
            downloadLink.href = fileURL;
            downloadLink.download = `PatchBulletins ${pg.month}-${pg.year}.csv`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });

        $event.stopPropagation();
        $event.preventDefault();
    }

    public openExportJson($event: Event, pg : CRS.RSPatchGroup) : void {

        var bearerAuth = `Bearer ${this.authService.authToken}`;
        var urlBase = this.utilService.getAPIUrl( "Admin/AdminExportPatchJson?PatchGroupId=" + pg.id);

        const httpOptions = {
            headers: new HttpHeaders(
                { Authorization: bearerAuth, 'Content-Type': 'application/json; charset=utf-8' }
            )
        };

        this.http.get( urlBase, httpOptions).subscribe(data=>{
            var file = new Blob([JSON.stringify(data)], {type: 'application/json'});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });

        $event.stopPropagation();
        $event.preventDefault();
    }

    public generateBulletin($event: Event, pg: CRS.RSPatchGroup): void {
        this.dialogService.showYesNoMessage("Generate Bulletin", [`Are you sure that you want to generate the bulletin for ${pg.mnYr}`])
        .pipe(take(1))
        .subscribe((ans)=>{
            if (ans) {
                this.xadminService.adminGeneratePatchBulletin(pg.year, pg.month).then(()=>{
                    this.dialogService.showInfoMessage("Generate Patch Bulletin", [ "Bulletin is now being generated. This may take a few minutes" ]);
                });
            }
        })
        $event.stopPropagation();
        $event.preventDefault();
    }

    public setComplete($event: Event, pg: CRS.RSPatchGroup): void {
        this.dialogService.showYesNoMessage("Generate Checks", ["Are you sure that you want to generate checks for this update?. This is not reversible"])
        .pipe(take(1))
        .subscribe((ans)=>{
            if (ans) {
                this.xadminService.adminSetPatchGroupComplete(pg.id).then(() => {
                    this.update();
                    this.dialogService.showInfoMessage("Generate Checks", [ "Checks are now being generated. This may take a few minutes" ]);
                });
            }
        })
        $event.stopPropagation();
        $event.preventDefault();
    }

    public getCCGStateName(state: number) : string {
        return CCGState[state];
    }

    private patchUpdateRequestCommand: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.xadminService.adminRequestPatchUpdateCheck().then((data)=> {
                this.dialogService.showInfoMessage("Patch Update Request", ["Request acknowledged"]);
            });
        }
    )

}
