import { Injector } from '@angular/core';
import { NavigationService } from '../svc/navigationService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';

export class ViewPackageCommand {

    constructor(
        private injector: Injector
    ) {
        this.navigation = this.injector.get(NavigationService);
        this.menuService = this.injector.get(MenuService);
        this.currentApplication = this.injector.get(CurrentApplicationService);

        this.menuService.register(Constants.MENU_VIEW_PACKAGE, this.cmd, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN )
    }

    private navigation: NavigationService;
    private menuService: MenuService;
    private currentApplication: CurrentApplicationService;

    private cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentApplication.browser.canBrowsePackage;
        },
        () => {
            this.navigation.goApplicationBrowseFULL(this.currentApplication.dataItem.projectId, this.currentApplication.dataItem.id);
        },
        ()=> { 
            return true;
            // let ret: boolean =  false;
            // if (this.application === undefined || !this.utilService.isSpecialFeatureAvailable('MSIED'))
            //     ret = false;
            // else if (this.showViewOptions(false))
            //     ret= true;
            // return ret;
        }
    );

 
}