<div fxLayout="row" style="max-width:1800px" fxLayoutGap="25px">

    <div fxFlex fxLayout="column" class="sw-big-vert-pad-top" *ngIf="projectIsReady">
        <div fxFlex="none">
            <h2>Stage Items</h2>
        </div>
        <div fxFlex="none" fxLayout="column">
            <div style="max-width: 500px">
                <input-edit datatype="MSTRING" rows="20" [disable]="!canEdit()" label="One per line" [value]="currentProject.workflowStagesSplit" (on-change)="updateWorkflowStages($event)"></input-edit>
            </div>
        </div>
        <div fxFlex="none" class="sw-subtext">
            Stages suffixed with '*' will trigger emails where enabled, '!' enables inclusion in invoicing report
        </div>
    </div>

    <div fxFlex fxLayout="column" class="sw-big-vert-pad-top" *ngIf="projectIsReady">
        <div fxFlex="none">
            <h2>Status Change Communication</h2>
        </div>
        <div fxFlex>
            <workflow-status-communication></workflow-status-communication>
        </div>
    </div>  

    <div fxFlex="5"></div>

</div>