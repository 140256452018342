import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import * as CRS from '../api/CRS'

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    constructor()
    {
        this.sub =new Subject<number>();
    }

    private _platformSelections = {};
    private _lastProjectId:string=null;

    public getCurrent(projectId?: string): number {
        if (projectId || this._lastProjectId) {
            var r = this._platformSelections[projectId?projectId:this._lastProjectId];
            return (r==undefined) ? 1 : r;
        }
        return null;
    }

    public setCurrent(projectId: string, platform: number) {
        this._platformSelections[projectId] = platform;
        this._lastProjectId=projectId;
        //this.$rootScope.$broadcast(Constants.EVENT_PROJECTIDCHANGED, platform);
    }

    private sub: Subject<number>;
    public onPlatformChanged() : Observable<number> {
        return this.sub.asObservable();
    }

    public getPlatformElements(platform: CRS.RSPlatform) : Array<string> {
        let elements : Array<string> =[];
        elements.push(platform.osIdTo.toUpperCase());
        if (platform.officeSuite)
            elements.push(platform.officeSuite.toUpperCase());
        if (platform.browser)
            elements.push(platform.browser.toUpperCase());
        if (platform.dotNetVersion)
            elements.push(platform.dotNetVersion.toUpperCase());
        if (platform.virtualisationTechnology)
            elements.push(platform.virtualisationTechnology.toUpperCase());
        return elements;
    }

    public getPlatformProductIds(platform: CRS.RSPlatform) : Array<String> {
        return platform.productIds;
    }

}
