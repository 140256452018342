import { Injector } from '@angular/core';
import { NavigationService } from '../svc/navigationService';
import { take } from 'rxjs/operators';
import { CurrentProjectService } from '../svc/currentProjectService';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentConversionJobService } from "../svc/currentConversionJobService";
import { DialogService } from '../svc/dialogService';
import { MenuService } from '../svc/menuService';
import { UtilService } from '../svc/utilService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';

import * as Enums from '../api/Enum';
import { utils } from 'protractor';

export class ResetApplicationActionCommand {

    constructor(
        private injector: Injector
    ) {
        this.navigation = this.injector.get(NavigationService);
        this.menuService = this.injector.get(MenuService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentConversionJob = this.injector.get(CurrentConversionJobService);
        this.dialogService = this.injector.get(DialogService);
        this.utilService = this.injector.get(UtilService);
        
        this.menuService.register(Constants.MENU_RESET_APPLICATION_ACTION, this.cmd, Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN )

        this.currentApplication.dataContext.onRefreshed().subscribe((v)=>{
            this.currentApplication.browser.canViewPackageRefresh();
        });
    }

    private navigation: NavigationService;
    private dialogService: DialogService;
    private currentProject: CurrentProjectService;
    private currentApplication: CurrentApplicationService;
    private currentConversionJob: CurrentConversionJobService;
    private utilService: UtilService;
    private menuService: MenuService;

    private cmd: AppCommand = AppCommand.create(
        ()=> {
            if (this.currentConversionJob.dataContext.isReady && this.currentConversionJob.dataContext.item()) {
                var tsk = this.currentConversionJob.dataItem.task;
                if (tsk && tsk.status > Enums.SpineStatus.InProgress) {
                    if (tsk.jobType != 30 || tsk.status != Enums.SpineStatus.Succeeded)
                        return true;
                }
            }
            return false;
       },
        ()=> {
            this.dialogService.showYesNoMessage("Delete Action", ["Are you sure that you want to delete this action?"])
            .pipe(take(1))
             .subscribe((ans)=> {
                if (ans) {
                    let jobId = this.currentConversionJob.dataContext.id;
                    var act = this.currentApplication.findActionById(jobId);
                    if (act) {
                        if (this.currentConversionJob.isRepackJob()) {
                            this.currentApplication.setNoRepack();
                        }
                        this.currentConversionJob.resetJob().then(()=>{
                            this.currentApplication.dataContext.refreshCurrent();
                            this.navigation.goApplicationJobFULL(this.currentProject.dataContext.id, this.currentApplication.dataContext.id, act.baseId);
                         });
                    }
                }
            });
        }
    );

}
