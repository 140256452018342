
<div fxLayout="column" fxFlexFill fxLayoutAlign="start center" *ngIf="!authService.isLoggedIn">
  <div fxFlex></div>
    <no-auth fxFlex="none"></no-auth>
  <div fxFlex></div>
</div>

<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill *ngIf="authService.isLoggedInResolved() && authService.isLoggedIn">
 
  <heading-bar fxFlex="none" [user]="user"></heading-bar>

  <div fxFlex>
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>

  <footer *ngIf="authService.isLoggedIn">
    <footing-bar></footing-bar>
  </footer>

</div>