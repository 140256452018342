import { AppCommand } from '../../model/AppCommand';
import { BreadcrumbService } from '../../svc/breadcrumbService'
import { MenuService } from '../../svc/menuService';
import { Constants } from 'src/app/api/Constants';
import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { AdminPatchGroups } from '../admin-patch-groups/admin-patch-groups';
import { NavigationService } from 'src/app/svc/navigationService';

import * as STHS from '../../svc/stateHelperService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: "admin-patch-group-item",
    templateUrl: './admin-patch-group-item.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss', '../../shared/css/view.scss']
})

export class AdminPatchGroupItem implements OnInit, AfterContentChecked{

    static selectedKB: string = '';
    static selectedItem: string = '';
    public classReference = AdminPatchGroupItem;

    constructor(
        private navigation: NavigationService,
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
        private breadcrumbService: BreadcrumbService,
        private menuService: MenuService

    ) {
        this.menuService.register(Constants.MENU_BACK, this.backCommand, `P-${Constants.ROUTE_ADMINPATCHGROUPS}`);
        this.classReference.selectedKB = '';
        const urlArray = this.navigation.urlParts;
        if(urlArray.length > 5) this.classReference.selectedItem = urlArray[5];
        else this.classReference.selectedItem = '';
    }

    public stateHelperInstance: STHS.StateHelperInstance;
    public patchGroupItem: CRS.RSAdminGetPatchGroupItem=null;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_PATCHGROUP_MAIN);
        this.update();
    }
    public log(value) {console.log(value)}

    ngAfterContentChecked(){
        const urlArray = this.navigation.urlParts;
        if(urlArray.length > 5 && this.classReference.selectedItem != urlArray[5]) {
            this.classReference.selectedItem = urlArray[5];
            this.xadminService.adminGetPatchGroupItem(this.classReference.selectedItem).then((response) => {
                this.patchGroupItem = response.data;
                AdminPatchGroups.updateSelectedPatchgroup(this.patchGroupItem.patchGroupId)
                this.breadcrumbService.setPatchGroup(this.patchGroupItem);
            }, () => {
                console.error('Patch group item data couldn\'t be loaded');
            });
        }
    }

    public openKB(cc: CRS.RSAdminCheckCandidateGrp) {
        this.classReference.selectedKB = cc.remediationId;
        this.navigation.goAdminPatchGroupItemDetailFULL(cc.remediationId);
    }

    private update(): void {

        this.xadminService.adminGetPatchGroupItem(this.classReference.selectedItem).then((response) => {
            this.patchGroupItem = response.data;
            AdminPatchGroups.updateSelectedPatchgroup(this.patchGroupItem.patchGroupId)
            this.breadcrumbService.setPatchGroup(this.patchGroupItem);
        }, () => {
            console.error('Patch group item data couldn\'t be loaded');
        });
    }

    static updateSelectedItem(id: string) {
        this.selectedItem = id;
    }

    static updateSelectedServer(id: string) {
        this.selectedKB = id;
    }

    private backCommand: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.classReference.selectedItem = '';
            this.navigation.goAdminPatchGroupsFULL();
        }
    )

}
