import { Injector } from '@angular/core';
import { Constants } from '../api/Constants';
import { CurrentApplicationService  } from '../svc/currentApplicationService';
import { NavigationService } from '../svc/navigationService';

import * as CRS from '../api/CRS';

export class PackageFindNav {

    constructor(injector:Injector) {
        this.navigation = injector.get(NavigationService);
    }

    private navigation: NavigationService;

    public openTable(projectId:string,applicationId:string, item: CRS.RSMsiTable) {
        localStorage.setItem(Constants.LOCALSET_LAST_BROWSE_TABLE, item.name);
        this.navigation.goApplicationBrowseTableFULL(projectId, applicationId, item.name);
    }

    public openTableById(currentApplication: CurrentApplicationService, tableId:string) {
        var tbl = currentApplication.browser.packageDetails.tables.filter(x=>x.name == tableId)[0];
        this.openTable(
            currentApplication.dataItem.projectId, 
            currentApplication.dataContext.id,
            tbl);
        localStorage.setItem(Constants.LOCALSET_LAST_BROWSE_TABLE, tableId);
    }

}