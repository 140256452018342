import { Component } from '@angular/core';
import { Constants } from '../api/Constants';
import { NavigationService } from '../svc/navigationService';

@Component({
    selector: Constants.ROUTE_ERROR_404,
    templateUrl: './error-not-found.html',
    styleUrls:['./error-view.scss']
  })
 
export class ErrorNotFound {

    constructor(
        private navigation: NavigationService
    ){
    }

    public goHome() {
        this.navigation.goHome();
    }

}