import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription} from 'rxjs';
import { first } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { Constants } from '../../api/Constants';
import { NavigationService } from '../../svc/navigationService'
import { StateHelperService } from '../../svc/stateHelperService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { UtilService } from '../../svc/utilService';
import { AuthService } from '../../svc/authService';
import { UserCacheService } from '../../svc/userCacheService';

@Component({
  selector: Constants.ROUTE_PROJECTDASHBOARD,
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})
export class Dashboard implements OnInit, OnDestroy {

  constructor(
    private navigation: NavigationService,
    private stateHelperService: StateHelperService,
    private msalAuthService: MsalService,
    private currentProject: CurrentProjectService,
    private userCacheService: UserCacheService,
    public utilService: UtilService,
    public authService: AuthService,

    ) { 
    }

  public hasProjectResolved: boolean=false;
  public newestProject: string;

  private subscriptions: Array<Subscription> =new Array<Subscription>();

  ngOnInit(): void {
    this.msalAuthService.handleRedirectObservable().subscribe({
      next: (result) => console.log(result),
      error: (error) => console.log(error)
    });
    if (this.authService.isLoggedIn) {
      this.checkHasProject();
      this.subscriptions.push(this.authService.onLoggedIn().subscribe(()=> { this.checkHasProject(); }));
      this.subscriptions.push(this.userCacheService.onUserLoaded().subscribe(()=>{ this.checkHasProject(); }));
    }
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach((x=>x.unsubscribe()));
  }

  public addProject() {
    this.navigation.goProjectCreate();
  }

  private checkHasProject() {
    if (this.userCacheService.isReady) {
      this.currentProject.hasProject().pipe(first()).subscribe((data)=> {
        this.hasProjectResolved=true;
        this.newestProject=data;
        this.checkRedirect();
      });
    }
  }

  private checkRedirect() {
    var rc =this.stateHelperService.details.statePathCurrent;
    if (rc && rc.id == Constants.ROUTE_PROJECTDASHBOARD && this.newestProject) {
      var uinf = this.userCacheService.GetUserInfo();
      var lastProj = this.utilService.localStorageHelper.getLastProject(uinf);
      var lastSvr = this.utilService.localStorageHelper.getLastServerId(uinf);
      if (lastSvr && lastSvr!="null" && lastSvr!=this.utilService.serverId)
        this.utilService.serverId = lastSvr;
      if (!lastProj || !this.utilService.isGuid(lastProj))
        lastProj = this.newestProject;
        this.navigation.goProjectApplicationsFULL(lastProj);
    }
    else if (!this.newestProject) {
      this.navigation.goProjectCreate();
    }
  }
}
