import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { UtilService } from 'src/app/svc/utilService';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Constants } from '../../api/Constants';
import { AuthService } from 'src/app/svc/authService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-application-output',
  templateUrl: './project-application-output.html',
  styleUrls: ['project-application-output.scss']
})
export class ProjectApplicationOutput implements OnInit, OnDestroy {

  constructor(
      private http:HttpClient,
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService, 
      private utilService: UtilService,
      private authService: AuthService
  ) { }

  public treeControl :NestedTreeControl<CRS.RSAction>;
  public dataSource: MatTreeNestedDataSource<CRS.RSAction>;
  public currentNode: CRS.RSAction=null;

  private subscriptions : Array<Subscription> = new Array<Subscription>();

    ngOnInit(): void {
        this.treeControl = new NestedTreeControl<CRS.RSAction>(node => node.children);
        this.dataSource = new MatTreeNestedDataSource<CRS.RSAction>();
        this.update();
        this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe(()=> {
            this.update();
        }));
        this.subscriptions.push(this.currentApplication.dataContext.onRefreshed().subscribe(()=>{
            this.update();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public isDataReady() : boolean {
        return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady;
    }

    public isCurrent(action: CRS.RSAction) :boolean {
        return action.action == this.utilService.localStorageHelper.lastOutputActionId
    }
    
    public openAction(action: CRS.RSAction) {
        this.utilService.localStorageHelper.lastOutputActionId = action.action;
    }
    
    public hasChild = (_: number, node: CRS.RSAction) => !!node.children && node.children.length > 0;

      // Maintain a list of the expanded nodes to be used on next refresh
    private expandedNodes: Array<string> = [];
    public nodeExpandChanged(nodes?: Array<CRS.RSAction>) {
        if (!nodes)
            this.expandedNodes =[];
        var n = (nodes) ? nodes : this.dataSource.data;
        n.forEach((n)=>{
            if (this.treeControl.isExpanded(n) && n.children.length> 0) {
                this.expandedNodes.push(n.action);
                this.nodeExpandChanged(n.children);
            }
        });
        if (!nodes) {
            this.utilService.localStorageHelper.expandedOutputActionTreeNodes = this.expandedNodes;
        }
    }

    public download(action: CRS.RSAction){
        if (action.downloadUrl) {
            var fileLink = document.createElement('a');
            fileLink.href = action.downloadUrl;
            fileLink.click();     
        }
    }

    public prepare(action: CRS.RSAction){
        var applicationId = this.currentApplication.dataContext.id;
        var urlPart = `application/assureApplicationFile?applicationid=${applicationId}&serverId=${this.utilService.serverId}`;
        let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': this.authService.authToken });
        let options = { headers: headers };
        var url = this.utilService.getAPIUrl( urlPart);
        this.http.post(url, null, options).subscribe(
            res =>{
                action.requiresPreparationState = Constants.ASSUREZIPSTATE_INPROGRESS;
            },
            err => {
                console.log(err.message);
            }
        );
    }

    public isPrepareRequired(action: CRS.RSAction): boolean {
        return action.requiresPreparationState == Constants.ASSUREZIPSTATE_NOTDONE;
    }

    public isPreparing(action: CRS.RSAction): boolean {
        return action.requiresPreparationState == Constants.ASSUREZIPSTATE_INPROGRESS || action.requiresPreparationState == Constants.ASSUREZIPSTATE_INPROGRESSHANDLED;
    }

    private update() {
        if (this.currentApplication.dataContext.isReady){
            var actions = this.currentApplication.dataItem.outputActions;
            this.dataSource.data=actions;
            this.expandRootTreenodes(actions)
        }
    }

    private expandRootTreenodes(data: Array<CRS.RSAction>) {
        var expandedSet = this.utilService.localStorageHelper.expandedOutputActionTreeNodes;
        data.forEach((n)=> {
          var toExpand = (expandedSet) ? expandedSet.indexOf(n.action) >= 0 : true;
          if (toExpand) {
            this.treeControl.expand(n);
            if (n.children)
              this.expandRootTreenodes(n.children);
          }
        })
      }
    
}
