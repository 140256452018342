import { Component } from '@angular/core';

@Component({
    selector: 'project-application-companions',
    templateUrl: './project-application-companions.html',
    styleUrls: []
})
export class ProjectApplicationCompanions {

  constructor(
  ) { }

}


