import { RoutePathSegment } from '../model/RoutePathSegment';
import { Constants } from '../api/Constants';
import { UtilService } from '../svc/utilService';
import { NavigationService } from '../svc/navigationService';

export class StateElements {

    constructor(utilService: UtilService) {
        this.utilService=utilService;
        this.projectId=null;
        this.applicationId=null;
        this.applicationIdCompressed=null;
        this.assessmentGroupId=null;
        this.jobId=null;
        this.checkId=null;
        this.actionId=null;
        this.tableId=null;
        this.ruleGroupId=null;
        this.userId=null;
    }

    private utilService: UtilService;

    public projectId:string;
    public applicationId:string;
    public applicationIdCompressed:string;
    public assessmentGroupId:string;

    public jobId:string;
    public checkId:string;
    public actionId:string;
    public tableId:string;
    public ruleGroupId: number;
    public userId:string;

    public serverId: string;

    private _statePath : Array<RoutePathSegment>;
    public get statePath() {
        return this._statePath;
    }

    public get statePathCurrent() : RoutePathSegment {
        return this._statePath.slice(-1)[0]; // last()
    }

    public get statePathParent() : RoutePathSegment {
        const startPath = this.statePath.slice(-2)[0] // last but one element in the array
        if(startPath.param){ // dashboard/admin
            return {id: startPath.id + '/' + startPath.param, param: null, query:null}
        }
        return this.statePath.slice(-2)[0] 
    }

    public updateStatePath(value: Array<RoutePathSegment>)
    {
        this._statePath = value;
        this.statePathPropUpd();
        if (this.serverId && this.utilService.serverId != this.serverId)
            this.utilService.serverId = this.serverId;
    }

    public isStatePathCurrent(id:string) : boolean {
        var spc = this.statePathCurrent;
        return (spc)?spc.id == id:false;
    }

    public isStatePathParent(id:string) : boolean {
        var spc = this.statePathParent;
        return (spc)?spc.id == id:false;
    }

    public statePathFind(value:string) : any {
        return this._statePath.find(x=>x.id == value)!=null;
    }

    public Clone() : StateElements {
        var ret =new StateElements(this.utilService);
        ret.projectId = this.projectId;
        ret.applicationId = this.applicationId;
        ret.assessmentGroupId = this.assessmentGroupId;
        ret.jobId = this.jobId;
        ret.checkId = this.checkId;
        ret.actionId = this.actionId;
        ret.tableId = this.tableId;
        ret.ruleGroupId = this.ruleGroupId;
        ret.userId = this.userId;
        return ret;
    }

    private statePathPropUpd() {

        let hasTbl:boolean=false;
        let hasAg:boolean=false;
        let hasChk:boolean=false;
        let hasAId:boolean=false;
        let hasPrjId:boolean=false;
        let hasActId:boolean=false;
        let hasJobId:boolean=false;
        let hasUserId:boolean=false;

        this.statePath.forEach((x)=> {
            switch(x.id) {
                case Constants.ROUTE_PROJECTDETAILS_MAIN:
                    var p = x.param;
                    var reg = new RegExp(/^(.+)@([a-z]+)$/);
                    var m = p.match(reg);
                    if (m) {
                        this.serverId = m[2];
                        p=m[1];
                    }
                    this.projectId=NavigationService.expandGuid(p);
                    hasPrjId=true;
                    break;
                case Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN:
                    this.applicationIdCompressed =x.param;
                    this.applicationId=NavigationService.expandGuid(x.param);
                    hasAId=true;
                    break;
                case Constants.ROUTE_PROJECTREPORTAG_MAIN:
                    this.assessmentGroupId = x.param;
                    hasAg=true;
                    break;
                    case Constants.ROUTE_PROJECTQUERIESSTANDARDS:
                    this.ruleGroupId = Constants.RULE_GROUP_STANDARDS;
                    break;
                case Constants.ROUTE_PROJECTQUERIESCHECKSHEET:
                    this.ruleGroupId = Constants.RULE_GROUP_QACHECKSHEET;
                    break;
                case  Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN:
                    this.checkId = x.param;
                    hasChk=true;
                    break;
                case Constants.ROUTE_ADMINUSERDETAIL_MAIN:
                    this.userId = x.param;
                    hasUserId=true;
                    break;
                case Constants.ROUTE_PROJECTCREATE:
                    if (x.query && x.query["svr"])
                        this.serverId = x.query["svr"];
                    break;
                case Constants.ROUTE_PROJECTACTIONDETAILS_MAIN:
                case Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN:
                    if (this.utilService.isGuid(x.param)) {
                        this.jobId = x.param;
                        this.actionId = null;
                        hasJobId=true;
                    }
                    else if(this.utilService.isGuid(NavigationService.expandGuid(x.param))) {
                        this.jobId = NavigationService.expandGuid(x.param);
                        this.actionId=null;
                        hasJobId=true;
                    }
                    else {
                        this.actionId = x.param;
                        this.jobId=null;
                        hasActId=true;
                    }
                    break;
                case Constants.ROUTE_PROJECTAPPLICATIONBROWSETABLE_MAIN:
                    this.tableId = x.param;
                    hasTbl=true;
            }
        });
        if (!hasTbl)
            this.tableId=null;
        if (!hasChk)
            this.checkId=null;
        if (!hasAg)
            this.assessmentGroupId=null;
        if (!hasAId)
            this.applicationId=this.applicationIdCompressed=null;
        if (!hasPrjId)
            this.projectId=null;
        if (!hasActId)
            this.actionId=null;
        if (!hasJobId)
            this.jobId=null;
        if (!hasUserId)
            this.userId=null;

    }
}