<div class="full-width-bs full-height-bs" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex></div>
    <div fxFlex="none">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="top center">
            <div fxFlex="none"><img src="/img/misc/warning.png" width="48px" height="48px"></div>   
            <div fxFlex><h1 class="sw-upper">AN ERROR HAS OCCURRED</h1></div>
            <div class="sw-error">{{message}}</div>  
        </div>
    </div>
    <div fxFlex></div>
</div>
