import { Component,OnInit } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { StateHelperService } from 'src/app/svc/stateHelperService';
import { NavigationService } from 'src/app/svc/navigationService';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'conversion-task-converted',
  templateUrl: './conversion-task-converted.html',
  styleUrls: ['./conversion-task-converted.scss']
})
export class ConversionTaskConverted implements OnInit {

    constructor(
        public currentConversionJob: CurrentConversionJobService,
        public currentApplication: CurrentApplicationService,
        private navigation: NavigationService,
        private stateHelperService: StateHelperService,
        private utilService: UtilService,
    ) { }

    ngOnInit(): void {
    }

    public isDataReady()  : boolean {
        return !this.utilService.isEmptyAny(this.task);
    }

    public get task() : CRS.RSGetConversionTask {
        if (!this.currentConversionJob.dataContext.isReady)
            return null;
        if (!this.currentConversionJob.hasTask())
            return null;
        return this.currentConversionJob.dataItem.task;
    }

    public isCancellingTask() : boolean {
        return this.task && this.task.cancelling && this.task.status == 1; 
    }

   public goToApp($event) : boolean {
        this.navigation.goApplicationJobFULL(
            this.stateHelperService.details.projectId,
            this.task.applicationId,
            this.task.id
        );
        return false;   
    }

   public isApplicationBasedRoute() {
    return !this.utilService.isEmpty(this.stateHelperService.details.applicationId);
  }

}
