import { Component, OnInit, Injector } from '@angular/core';
import { Observable, Subscription, timer} from 'rxjs';
import { AuthService } from '../../svc/authService';
import { UserCacheService } from '../../svc/userCacheService';
import { StylingService } from '../../svc/stylingService';
import { NavigationService } from 'src/app/svc/navigationService';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { AppUploadService } from '../../svc/appUploadService';
import { NotifyItem } from '../../model/NotifyItem';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { DriveScanService } from '../../svc/driveScanService';

@Component({
    selector: "footing-bar",
    templateUrl: './footingBar.html',
    styleUrls:['./footing-bar.scss',  '../../shared/css/buttons.scss']
})
export class FootingBar implements OnInit {

    constructor(
        private driveScanService: DriveScanService,
        private stateHelperService: StateHelperService,
        private currentConversionJob: CurrentConversionJobService,
        private currentApplication: CurrentApplicationService,
        public appUploadService: AppUploadService,
        public currentProject: CurrentProjectService,
        public authService: AuthService,
        public userCacheService: UserCacheService,
        public navigation: NavigationService,
        public utilService: UtilService,
    ) {
    }

    ngOnInit(): void {
        this.currentProject.dataContext.onLoaded().subscribe(()=> {
            this.refreshToasts();
          });
          this.currentProject.dataContext.onRefreshed().subscribe(()=> {
            this.refreshToasts();
          });
    }

    public getNotClass() {
        return (this.currentNotification!=null) ? "slide-up" : null;
    }

    public currentNotification: NotifyItem;

    public timerSource : Observable<number>= timer(5000,5000);
    public timerSourceSubscription: Subscription = this.timerSource.subscribe(()=>this.notificationRefresh());
    public _notificationQueue: Array<NotifyItem> = [];
  
    // This runs every 5s and manages the current notification to displayed in the footer. There is a 3s delay
    // between each one becoming active. Fifo.
    public endTime:Date =null;
    private notificationRefresh() {
      
      var now = new Date();
      if (this.endTime == null || this.endTime < now) {
        if (this.currentNotification == null) {
          this.currentNotification = this._notificationQueue.shift();
          if (this.currentNotification==null) {
            this.endTime = null;
          }
          else {
            this.endTime = new Date();
            this.endTime.setSeconds(this.endTime.getSeconds() + 10);
          }
        }
        else { // Give it 3 seconds before taking next one
          this.currentNotification = null;
          this.endTime = new Date();
          this.endTime.setSeconds(this.endTime.getSeconds() + 3);
        }
      };
    }
  
    private refreshToasts() {
        var notifications: Array<NotifyItem> = this.currentProject.getNotifications();
        if (notifications) {
          notifications.forEach(x=>this._notificationQueue.push(x));
        }
    }

    public get currentProjectLastScanned() {
        if (this.stateHelperService.details.statePathFind(Constants.ROUTE_PROJECTDRIVES)) {
          if (this.stateHelperService.details.statePathCurrent) {
            if (this.driveScanService.isDataReady && this.driveScanService.lastStartedExpression) {
              return "Last scanned " + this.driveScanService.lastStartedExpression;
            }
          }
        }
        return null;
    }

    public get currentProjectOwner(): string {
        if (this.currentProject.dataContext.isReady && !this.currentProject.dataContext.isInError && !this.currentProject.dataItem.isOwner) 
          return this.currentProject.dataItem.owner;
        return null;
    }
  
    public get canShowRemotingUrlLink(): boolean {
        if (!this.currentConversionJob.isInProgress())
          return false;
        if (this.currentConversionJob.isCancelling())
          return false;
        if (!this.currentConversionJob.getRemotingMachineUrlRaw())
          return false;
        if (this.utilService.privileges?.remotingUrl)
          return true;
    
        var tsk = this.currentConversionJob.dataItem.task;
        return tsk.canRelease && tsk.remotingUrl && this.utilService.privileges?.copyVmUrl;
      }
    
      public get uiDetected(): boolean {
        if (!this.currentConversionJob.dataContext.isReady)
          return false;
        if (!this.currentConversionJob.isInProgress())
          return false;
        if (!this.currentConversionJob.isCancelling())
          return false;
        return this.currentConversionJob.uiDetected();
      }
    
      public copyRemotingUrlToClipboard() : void {
        var $temp_input = $("<input>");
        $("body").append($temp_input);
        $temp_input.val(this.currentConversionJob.getRemotingMachineUrlRaw()).select();
        document.execCommand("copy");
        $temp_input.remove();
      }
   
      public get currentAppSourceMissing(): boolean {
        if (!this.currentApplication.dataContext.isReady)
          return false;
        return this.currentApplication.dataItem?.sourceAvailabilityState == Constants.SOURCE_AVAILABILITY_MISSING;
      }
    
      public get currentAppSourceUndefined(): boolean {
        if (!this.currentApplication.dataContext.isReady)
          return false;
        return this.currentApplication.dataItem?.sourceAvailabilityState == Constants.SOURCE_AVAILABILITY_NOTDEFINED;
      }
    
      public get currentJobVerified(): boolean {
        if (!this.currentConversionJob.dataContext.isReady)
          return false;
        if (!this.currentConversionJob.isInProgress())
          return false;
        var j = this.currentConversionJob.dataItem;
        return j&&j.task&&j.task.isExecutingVerified;
      }
}