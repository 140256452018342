<mat-toolbar fxLayoutGap="20px">
    <div fxFlex="none" *ngIf="currentProject.imageUrl" class="project-image-medium" [backgroundImage]="currentProject.imageUrl" backgroundSize="contain" backgroundRepeat="false">
    </div>
    <div fxFlex="none" *ngIf="appUploadService.containsItems()"> 
      <file-upload-queue></file-upload-queue>
    </div>
    <div fxFlex>
      <div fxLayout="row" fxLayoutAlign="center">
          <div fxFlex="none">
            <div class="slide">
              <span [ngClass]="getNotClass()">{{currentNotification?.text}}</span>
            </div>
          </div>
      </div>
    </div>
    <div fxFlex="none" *ngIf="uiDetected">
      <mat-icon>keyboard</mat-icon>
    </div>
    <div fxFlex="none" *ngIf="currentProjectOwner">
      <div fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="none"><mat-icon class="grey">person</mat-icon></div>
        <div fxFlex="none" class="footer-text">{{currentProjectOwner}}</div>
      </div>  
    </div>
    <div fxFlex="none" class="footer-text" *ngIf="currentProjectLastScanned">{{currentProjectLastScanned}}</div>
    <div fxFlex="none" *ngIf="canShowRemotingUrlLink">
        <button mat-button (click)="copyRemotingUrlToClipboard()" title="Copy remoting url to clipboard">
            <mat-icon>content_paste</mat-icon>
        </button>
    </div>
    <div fxFlex="none" *ngIf="currentAppSourceMissing">
      <div fxLayout="row" fxLayoutGap="10px" class="sw-warning">
          <div fxFlex="none">
              <mat-icon>warning</mat-icon>
          </div>
          <div fxflex class="sw-upper">
              The source wasn't found for this application
          </div>
      </div>
    </div>
    <div fxFlex="none" *ngIf="currentAppSourceUndefined">
      <div fxLayout="row" fxLayoutGap="10px" class="sw-warning">
          <div fxFlex="none">
              <mat-icon>warning</mat-icon>
          </div>
          <div fxflex class="sw-upper">
              The source isn't defined for this application
          </div>
      </div>
    </div>
    <div fxFlex="none" *ngIf="currentJobVerified">
        <div class="sw-job-verified">
          <div class="pulse-blob"></div>
        </div>
    </div>
    <div fxFlex="none">
      <div style="width:20px"></div>  
    </div>
  </mat-toolbar>  