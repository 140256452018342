import { Injector, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { DialogService } from '../../svc/dialogService';
import { GridAssistant } from 'src/app/model/gridAssistant';
import { NavigationService } from 'src/app/svc/navigationService';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: 'admin-projects',
    templateUrl: './admin-projects.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss']
  })

export class AdminProjects implements OnInit, OnDestroy {

    constructor(
        private injector: Injector,
        private navigation: NavigationService,
        private xadminService: SVC.AdminService,
        private dialogService: DialogService,
    ) {

    }

    public gridAssist: GridAssistant<CRS.RSAdminGetProjects, CRS.RSProject>;

    ngOnInit() {
        this.gridAssist = new GridAssistant<CRS.RSAdminGetProjects,CRS.RSProject>(Constants.ROUTE_ADMINPROJECTS, this.injector);
        this.gridAssist.setColumns(   [         
                { id: 'name', name: 'User', field: 'ownerEmail', sortable: true, width: 200 },
                { id: 'user', name: 'Name', field: 'name', sortable: true, width: 200 },
                { id: 'type', name: 'Enabled', field: 'enabled', sortable: true, width: 80 }
            ]
        );
        this.gridAssist.allowRowSelection((r)=>this.openProject(r));
        this.gridAssist.setUpdate(()=>this.update());
    }

    ngOnDestroy(): void {
        this.gridAssist.dispose();
    }

    private update(): void {
        this.xadminService.adminGetProjects().then((response) => {
            this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSProject );
        }, () => {
            console.error('Project data couldn\'t be loaded');
        });
    }

    public isCurrentProject(prj: CRS.RSProject) : boolean {
        let r= this.navigation.url.includes("/" + Constants.ROUTE_ADMINDASHBOARD + "/" + Constants.ROUTE_ADMINPROJECTS) && this.navigation.getCurrentRouteParameter("prjId") == prj.id;
        return r;
    }

    public openProject(prj: CRS.RSProject) {
        this.dialogService.showInfoMessage("Project List", [ "Not implemented"]);
    }
}
