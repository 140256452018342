import { Injector } from '@angular/core';
import { CurrentConversionJobService } from "../svc/currentConversionJobService";
import { NavigationService } from '../svc/navigationService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentProjectService } from '../svc/currentProjectService';
import { UtilService } from '../svc/utilService';
import { StateHelperService } from '../svc/stateHelperService';

import * as CRS from '../api/CRS';

export class PublishCommand {

    constructor(
        private injector: Injector
    ) {
        this.navigation = this.injector.get(NavigationService);
        this.menuService = this.injector.get(MenuService);
        this.currentConversionJob = this.injector.get(CurrentConversionJobService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.utilService = this.injector.get(UtilService);
        this.stateHelperService = this.injector.get(StateHelperService);

        this.menuService.register(Constants.MENU_PUBLISH_PACKAGE, this.cmd, Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN )
    }

    private navigation: NavigationService;
    private currentConversionJob: CurrentConversionJobService;
    private currentApplication: CurrentApplicationService;
    private currentProject: CurrentProjectService;
    private menuService: MenuService;
    private utilService: UtilService;
    private stateHelperService: StateHelperService;

    private get parentAction() : CRS.RSAction {
        var id = this.stateHelperService.details.actionId;
        let action = this.currentApplication.findActionById(id);
        if (action) {
            var parentId = `PLC${Constants.CONV_ACTION_DELIM}${action.parentageId}`;
            return this.currentApplication.findActionByBaseId(parentId);
        }
        else {
            return null;
        }
    }

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
             var r= this.currentApplication.dataContext.isReady 
                    && this.currentApplication.dataItem.canPublishOriginal
                    && !this.utilService.isEmptyAny(this.parentAction);
             return r;
        },
        ()=> {
            let parentAction =this.parentAction;
            let includeEvalResult = this.utilService.includeEvalResultsInReadme;
            let mergeTransformsDuringPublish = this.utilService.mergeTransformsDuringPublish;
            this.currentConversionJob.publish(parentAction.id,includeEvalResult,mergeTransformsDuringPublish).then((jobId)=>{
                this.currentApplication.dataContext.refreshCurrent();
                this.navigation.goApplicationJobFULL(
                    this.currentProject.dataContext.id,
                    this.currentApplication.dataContext.id,
                    jobId
                );
            });
        },
        ()=>{
            return false;
        }
    );

}
