import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { StateHelperService } from '../../svc/stateHelperService';
import { Constants } from '../../api/Constants';
import { NavigationService } from 'src/app/svc/navigationService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-queries-sidenav',
  templateUrl: './project-queries-sidenav.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectQueriesSideNav implements OnInit {

  constructor(
    private utilService:UtilService, 
    private stateHelperService: StateHelperService,
    private navigation: NavigationService) { }

  @Input('project') project : CRS.RSGetProject;

  ngOnInit(): void {
  }

  public navItems = [
    { text: "Standards", route: Constants.ROUTE_PROJECTQUERIESSTANDARDS, icon: "subject" },
    { text: "Checksheet", route: Constants.ROUTE_PROJECTQUERIESCHECKSHEET, icon: "subject" },
    { text: "Custom", route: Constants.ROUTE_PROJECTQUERIESCUSTOM, icon: "traffic" }
  ]

  public getClass(item: any) : string{
      var suffix = (this.stateHelperService.details.statePathFind(item.route)) ?"active":"inactive";
      return `sw-nav-option-${suffix}`;
  }

  public isReady() : boolean {
    return !this.utilService.isEmptyAny(this.project);
  }

  public disableClick(): boolean {
    return this.utilService.isEmptyAny(this.project);
  }

  public invoke(item:any)
  {
      this.navigation.goProjectQueriesFULL(this.project.id, item.route);
  }

  public isMaximised() :boolean {
      return true;
  }

  public navItemsFilter(item: any): boolean {
      return (!item.condition || item.condition())
  }

}
