import { Injector } from '@angular/core';
import { NavigationService } from '../svc/navigationService';
import { CurrentApplicationService } from "../svc/currentApplicationService";
import { ConversionService } from '../api/SVC';

export class CreateConversionTaskCommand {

    constructor(
        private injector:Injector
   ) {
       this.navigation = this.injector.get(NavigationService);
       this.conversionService = this.injector.get(ConversionService);
       this.currentApplication = this.injector.get(CurrentApplicationService);
    }

    private navigation:NavigationService;
    private conversionService:ConversionService;
    private currentApplication: CurrentApplicationService;

    private projectId:string;
    private applicationId:string;

    public args:string=null;
    public companionFiles: Array<string> = [];
    public virtualiseFromRepack:boolean=false;
    public fixTransformPlatform:number=0;
    public useResponseTransform:boolean=false;
    public useExtractTransform:boolean=false;
    public msiExtractOnly:boolean=false;
    public useArpProductDetails:boolean=false;
    public usePVAD:boolean=false;
    public PVADDirectory:string=null;
    public manualModificationRequired:boolean=false;
    public useExistingSnapshot: boolean=false;

    public execute(conversionType:string) {

        this.projectId=this.currentApplication.dataItem.projectId; 
        this.applicationId=this.currentApplication.dataItem.id;
 
        this.conversionService.convertApplication(
            this.projectId, 
            this.applicationId,
            conversionType, 
            this.args,
            null, 
            null,
            null,
            this.companionFiles,
            this.virtualiseFromRepack,
            this.fixTransformPlatform, 
            this.useResponseTransform, 
            this.useExtractTransform,
            this.msiExtractOnly, 
            this.useArpProductDetails,
            this.usePVAD,
            this.PVADDirectory,
            this.manualModificationRequired,
            this.useExistingSnapshot
            ).then((response)=> {
                this.redirect(response.data.jobId);
            });
    }

    
    private redirect(jobId:string) { 

        this.currentApplication.dataContext.refreshCurrent().then((data)=> {
            this.navigation.goApplicationJobFULL(this.projectId, this.applicationId, jobId);
        });
    }
}

